import { Form, Input, InputRef, Modal } from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import type { FormInstance } from "antd/es/form";
import "./EditableCell.module.scss";
import { showErrorNotification } from "src/util/notifications";

const EditableContext = createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: any;
}

export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} initialValues={index}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: any;
  moduleKey: string;
  editable: boolean;
  dataIndex: any;
  inputType: any;
  record?: any;
  index?: number;
  children?: React.ReactNode;
  onSaveChanges: any;
}

const EditableCell: React.FC<EditableCellProps> = ({
  onSaveChanges,
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;
  const [visible, setVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updatedValue, setUpdatedValues] = useState<any>();
  const inputRef = useRef<InputRef>(null);

  // Focus the input when editing starts
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  // Handle modal confirmation
  const modalValue = async (record: any, targetValue: any, name: any) => {
    try {
      const row = (await form.validateFields()) as any;
      setVisible(true);
      setUpdatedValues({
        record: record,
        targetValue: targetValue,
        name: name,
      });
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } 
      showErrorNotification(errMessage);
    }
  };

  // Handle the input nodes based on the `inputType`
  const inputNode =
    inputType === "Received" ? (
      <Input
        ref={inputRef}
        onPressEnter={(e: any) =>
          onSaveChanges(record, e.target.value, e.target.name)
        }
        onBlur={(e: any) =>
          onSaveChanges(record, e.target.value, e.target.name)
        }
        name={title}
        defaultValue={record?.Received}
        min={0}
      />
    ) : inputType === "OverrideOrderQuantity" ? (
      <Input
        ref={inputRef}
        onPressEnter={(e: any) =>
          modalValue(record, e.target.value, e.target.name)
        }
        onBlur={(e: any) => setEditing(false)}
        name={title}
        defaultValue={
          !record?.OverrideOrderQuantity
            ? record?.OrderQuantity
            : record?.OverrideOrderQuantity
        }
      />
    ) : (
      <Input
        ref={inputRef}
        prefix="$"
        onPressEnter={(e: any) =>
          onSaveChanges(record, e.target.value, e.target.name)
        }
        onBlur={(e: any) =>
          onSaveChanges(record, e.target.value, e.target.name)
        }
        name={title}
        defaultValue={record?.Cost}
      />
    );

  // Toggle between editing and viewing mode
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  return (
    <>
      <td {...restProps}>
        {(inputType === "OverrideOrderQuantity" && editing) ||
        (editable && (inputType === "Received" || inputType === "Cost")) ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please enter ${title}!`,
              },
              {
                pattern:
                  inputType === "Received"
                    ? new RegExp(/^[0-9]+$/)
                    : new RegExp(/^\d+(\.\d+)?$/),
                message: `${title} should be numeric`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : inputType === "OverrideOrderQuantity" ? (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        ) : (
          children
        )}
      </td>

      <Modal
        title="Update Confirmation"
        okText="Ok"
        centered
        width={400}
        open={visible}
        onOk={() => {
          onSaveChanges(
            updatedValue?.record,
            updatedValue?.targetValue,
            updatedValue?.name
          );
          setEditing(false);
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
          setEditing(false);
        }}
      >
        Are you sure you want to update the order quantity?
      </Modal>
    </>
  );
};

export default EditableCell;
