import { Checkbox, Divider, Form, Modal, Select, Spin, Table } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFramePickList, fetchPicker, MODULE_KEY, PICKER } from "./actions";
import "./../../styles/OpenPull.scss";
import useTableProps from "../../hooks/table/useTableProps";
import { ACTION, insertAt } from "../../util/utils";
import TableFilter from "../common/TableFilter/TableFilter";
import Label from "../common/Label/Label";
import Button from "../common/Button/Button";
import {
  printLabelReport,
  updateAcuityStatus,
  updatePrintLabel,
} from "./sagas";
import {
  DATE_FORMAT,
  FRAME_PICK_COLUMN,
  FRAME_PICK_DATA_TYPE,
} from "../../util/columns";
import { useMsal } from "@azure/msal-react";
import PDFStream from "../common/PDFStream/PDFStream";
import "./../../styles/FramePick.scss";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import GenericTable from "../common/Table/GenericTable";
import filterColumns from "./filterColumns";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
var toUint8Array = require("base64-to-uint8array");

const { Option } = Select;
let memoizePickerID = "";
const notification = new Notification("Frame Pick");
function FramePick() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [notFoundRow, setNotFoundRow] = useState<any>([]);
  const [stream, setStream] = useState<any>("");
  const tableProps = useTableProps(MODULE_KEY, [], "OrderNum");
  const { accounts } = useMsal();
  const userName = accounts?.length ? accounts[0].name : "";
  const [label, setLabel] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [pickerId, setPickerId] = useState<any>(memoizePickerID || undefined);
  const filterValues = filterColumns({
    selectedRow,
    setSelectedRow,
    notFoundRow,
    setNotFoundRow,
  });
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      let newRows = notFoundRow.filter(
        (notFoundSelectedRow: FRAME_PICK_DATA_TYPE) =>
          !selectedRows.find(
            (row: FRAME_PICK_DATA_TYPE) =>
              row.OrderNum == notFoundSelectedRow.OrderNum
          )
      );
      setNotFoundRow(newRows);
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: record.Status.trim() }
    },
    clearSelection: () => {
      setSelectedRow([]);
    },
    selectedRowKeys: selectedRow.map((row: any) => row.OrderNum),
  };

  const changePicker = (id: string) => {
    setPickerId(id);
    setSelectedRow([]);
    setNotFoundRow([]);
    memoizePickerID = id;
  };
  const handleGridData = (pickerId: any) => {
    const payload = {
      pickerId,
      isHoliday: true,
      offset: 0,
      limit: 100000,
    };
    dispatch(fetchFramePickList(payload));
  };

  useEffect(() => {
    if (pickerId || pickerId === 0) {
      handleGridData(pickerId);
    }
  }, [pickerId]);

  const acuityClickHandler = async () => {
    setLoader(true);
    try {
      const acuityData: any = [];
      selectedRow.forEach(
        ({ OfficeNum, OrderNum, ItemID }: FRAME_PICK_DATA_TYPE) => {
          acuityData.push({
            OfficeNumber: OfficeNum,
            OrderNumber: OrderNum,
            ItemID,
            ModifiedBy: userName,
            IsFound: true,
          });
        }
      );
      notFoundRow.forEach(
        ({ OfficeNum, OrderNum, ItemID }: FRAME_PICK_DATA_TYPE) => {
          acuityData.push({
            OfficeNumber: OfficeNum,
            OrderNumber: OrderNum,
            ItemID,
            ModifiedBy: userName,
            IsFound: false,
          });
        }
      );

      const res = await updateAcuityStatus(Object.values(acuityData));
      if (res) {
        showSuccessNotification(
          "Successfully updated the order status in Acuity."
        );
        setSelectedRow([]);
        setNotFoundRow([]);
        setOpen(false);
        setLoader(false);
        handleGridData(pickerId);
      } else {
        showErrorNotification(
          "Error occurred while updating the order status in Acuity."
        );
        setLoader(false);
        setOpen(false);
      }
    } catch (err) {
      showErrorNotification(
        "Error occurred while updating the order status in Acuity."
      );
      setLoader(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    dispatch(fetchPicker({}));
  }, []);

  const printLabelHandler = async (printType: any) => {
    setLoader(true);
    let records = [];
    setLabel(printType);
    if (printType === "all") {
      records = filterValues.data;
    } else {
      records = selectedRow;
    }
    try {
      const payload = {
        IdList: records.map((row: any) => row.Id),
        IsPrinted: false,
        PickerId: pickerId,
      };
      const res = await updatePrintLabel(payload);
      if (res) {
        const reportPayload = {
          reportName: "LabelFormNTEST",
          parameters: {
            Picker: pickerId,
          },
        };
        const printRes = await printLabelReport(reportPayload);
        if (printRes) {
          setStream(printRes);
        }
      }
    } catch (error) {
      showErrorNotification(
        undefined,
        "Print label request was not successful. Please try again"
      );
    }
    setLoader(false);
  };

  const onPrint = async () => {
    setLoader(true);
    try {
      let records = [];
      if (label === "all") {
        records = filterValues.data;
      } else {
        records = selectedRow;
      }
      const payload = {
        IdList: records.map((row: any) => row.Id),
        IsPrinted: true,
        PickerId: pickerId,
      };
      const res = await updatePrintLabel(payload);
      setStream("");
      setSelectedRow([]);
      setNotFoundRow([]);
      showSuccessNotification(res);
    } catch (error) {
      showErrorNotification(
        "Print label request was not successful. Please try again"
      );
    }
    setLoader(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setStream("");
    setSelectedRow([]);
    setNotFoundRow([]);
  };

  return (
    <Spin tip="Loading..." spinning={loader}>
      <div className="frame-pick-wrapper">
        <Divider />
        <Breadcrumb>
          <span>Frame Pick</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end">
            <Label label="Select Picker">
              <Select
                placeholder="Select"
                disabled={loader}
                showSearch
                value={pickerId}
                style={{ width: 120 }}
                optionFilterProp="children"
                onSelect={(value: string) => {
                  changePicker(value);
                }}
                size="small"
              >
                {filterValues.finalPickerArr?.map(
                  ({ PickerId, PickerName }: any) => {
                    return (
                      <Option key={PickerId} value={PickerId}>
                        {PickerName}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Label>
            <Button
              size="middle"
              disabled={loader || !tableProps.dataSource.length}
              onClick={() => printLabelHandler("selected")}
            >
              Print Selected Labels
            </Button>
            <Button
              size="middle"
              onClick={closeModal}
              disabled={loader || (!selectedRow.length && !notFoundRow.length)}
            >
              Update Acuity
            </Button>
            <Button
              size="middle"
              onClick={() => printLabelHandler("all")}
              disabled={loader || !tableProps.dataSource.length}
            >
              Print All Labels
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="OrderNum"
          columns={filterValues.columns}
          rowSelection={rowSelection}
          moduleKey={MODULE_KEY}
          xScroll={1500}
          yScroll={"calc(85vh - 17.5em)"}
          pagination={{
            defaultPageSize: 5000,
            showSizeChanger: true,
            pageSizeOptions: ["100", "500", "1000", "3000", "5000"],
          }}
        />
        {stream ? (
          <Modal
            open={true}
            onOk={() => setStream("")}
            onCancel={handleCancel}
            footer={null}
            width={1000}
            destroyOnClose
          >
            <PDFStream onPrint={onPrint} stream={stream} />
          </Modal>
        ) : null}

        <Modal
          title="Acuity Update Confirmation"
          okText="Ok"
          open={open}
          centered
          confirmLoading={loader}
          okButtonProps={{ disabled: loader }}
          style={{ top: 20 }}
          onOk={() => acuityClickHandler()}
          onCancel={() => setOpen(false)}
        >
          <span>Are you sure you want to Update Acuity?</span>
        </Modal>
      </div>
    </Spin>
  );
}

export default FramePick;
