import { Button, Dropdown, Menu, notification } from "antd";
import React, { useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../data/config/authConfig";
import AuthService from "../../../data/services/auth-service";
import styled from "styled-components";
import ProfilePanel from "./ProfilePanel";

export const onSignHandler = (isAuthenticated: boolean, instance: any) => {
  if (isAuthenticated) {
    notification.destroy();
    instance
      .logoutPopup()
      .then(() => localStorage.removeItem("accessToken"))
      .catch((e: any) => {
        console.error(e);
      });
  } else {
    instance
      .loginPopup(loginRequest)
      .then(() => AuthService.refreshAccessToken())
      .catch((e: any) => {
        console.error(e);
      });
  }
};

function Profile() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const userName = (accounts && accounts.length && accounts[0]?.name) || "";

  const authHandle = () => {
    onSignHandler(isAuthenticated, instance)
  }

  const menuItem = () => {
    let items = [
      {
        key: "signIn",
        icon: (
          <Button type={"link"} onClick={authHandle}>
            <span>Sign in</span>
          </Button>
        ),
        label: "",
      },
    ];
    if (isAuthenticated && accounts.length) {
      items = [
        {
          key: "signOut",
          icon: (
            <Button type={"link"} onClick={authHandle}>
              <span>Sign out</span>
            </Button>
          ),
          label: "",
        },
      ];
    }
    return items;
  };
  const menu = (
    <Menu className={"profile-menu"} items={menuItem()}>
      {/* {isAuthenticated && accounts.length ? (
        <>
          <p className='username'>{userName}</p>
          <Menu.Item key='signOut'>
            <Button type={'link'} onClick={authHandle>
              <span>Sign out</span>
            </Button>
          </Menu.Item>
        </>
      ) : (
        <Menu.Item key='signIn'>
          <Button type={'link'} onClick={authHandle>
            <span>Sign in</span>
          </Button>
        </Menu.Item>
      )} */}
    </Menu>
  );

  return (
    <>
      {isAuthenticated ?
        <ProfilePanel user={accounts[0]} onSignOut={authHandle} /> : null}
    </>
  );
}

export default Profile;

export const ProfileButton = styled(Button)`
  margin: 0 20px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 0 0 5px;
  justify-self: flex-end;
`;

