import { Descriptions, Form, Input } from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../util/columns";

interface DescriptionItems {
  orderDetails: any;
  form: any;
  invoiceNumber: any;
  setInvoiceNumber: any;
}
function DescriptionItems({
  orderDetails,
  form,
  invoiceNumber,
  setInvoiceNumber,
}: DescriptionItems) {
  return (
    <Descriptions
      column={4}
      labelStyle={{ fontWeight: "bold", fontSize: "12px" }}
      contentStyle={{ fontSize: "11px", marginTop: "3px" }}
    >
      <Descriptions.Item label="Order Number">
        {orderDetails.OrderNumber}
      </Descriptions.Item>
      <Descriptions.Item label="Order Date">
        {orderDetails.OrderDate
          ? dayjs(orderDetails.OrderDate).format(DATE_FORMAT)
          : null}
      </Descriptions.Item>
      <Descriptions.Item label="Vendor Name">
        {orderDetails.VendorName}
      </Descriptions.Item>
      <Descriptions.Item label="Invoice Number">
        <Form form={form}>
          <Form.Item
            name="invoiceNumber"
            rules={[
              {
                required: true,
                message: `Please enter invoice number!`,
              },
              {
                pattern: new RegExp(/^[a-zA-Z0-9]+$/i),
                message: "Invoice number should be alphanumeric",
              },
              {
                max: 30,
                message: "Max length should not be more than 30",
              },
            ]}
          >
            <Input
              size="small"
              value={invoiceNumber}
              onBlur={(val) => setInvoiceNumber(val.target.value)}
            />
          </Form.Item>
        </Form>
      </Descriptions.Item>
    </Descriptions>
  );
}

export default DescriptionItems;
