import { Card, Drawer, Form, Modal, Select, Space } from "antd";
import { useEffect, useState } from "react";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { validateOfficeList } from "src/components/kits/KitProcessing/sagas";
import { saveExpressOffice } from "./sagas";
import { getOffices } from "src/components/systemPreference/sagas";

function AddOffice({
  visible,
  setVisibleStatus,
  onLoad,
  setConfirmLoading,
}: any) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [officeData, setOfficeData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const [officeSelectMode, setOfficeSelectMode] = useState("");

  useEffect(() => {
    const location: any = [];
    const region: any = [];
    Promise.all([getOffices({})]).then((res) => {
      const sortedOffice: any = res[0]?.sort((a: any, b: any) => {
        return (
          a.LocationNumber !== null &&
          a.LocationNumber.localeCompare(b.LocationNumber, "en", {
            numeric: true,
          })
        );
      });
      setOfficeData(sortedOffice);
      sortedOffice.map((item: any) => {
        location.push({
          LocationNumber: item?.LocationNumber,
          LocationName: item?.LocationName,
        });
        if (!region.includes(item.Region)) {
          region.push(item.Region);
        }
      });
      const sortedRegion = region?.sort((a: any, b: any) => {
        return a?.localeCompare(b, "en", { numeric: true });
      });
      setLocationData(location);
    });
  }, []);

  const handleClose = () => {
    setVisibleStatus(false);
    frameDetailForm.resetFields();
    setConfirmLoading(false);
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      setConfirmLoading(true);
      const fields = frameDetailForm.getFieldsValue();
      let officeLocations: any = fields?.Offices?.split("(")[1].split(")")[0];
      let payload = {
        OfficeNumber: officeLocations,
      };
      const res = await saveExpressOffice(payload);
      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
        setConfirmLoading(false);
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error.response.data.error.messages[0];
        showErrorNotification(errMessage);
      }
      setConfirmLoading(false);
    }
  };
  const onSearch = (value: string) => {
    //console.log("search:", value);
  };

  const drawerTitle = "Add Express Office";
  return (
    <>
      <Modal
        title={drawerTitle}
        open={visible}
        onCancel={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        onOk={handleOk}
        okText="Ok"
        centered
      >
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
          >
            <Card>
              <div>
                <Form.Item
                  label="Offices"
                  name="Offices"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one office",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    allowClear={true}
                    placeholder="Select Offices"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[2] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                    optionLabelProp="label"
                    maxTagCount="responsive"
                  >
                    {locationData?.map((item: any, index) => {
                      return (
                        <Option
                          key={index}
                          value={`${item.LocationName} (${item.LocationNumber})`}
                        >
                          {item.LocationName} ({item.LocationNumber})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Modal>
    </>
  );
}
export default AddOffice;
