import {
  Card,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
} from "antd";
import Table from "antd/lib/table";
import { map } from "lodash";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOfficeLocation,
  fetchPrintTags,
  MODULE_KEY,
} from "./printTags-actions";
import { archiveTag, createPrintTag, updateTag } from "./printTags-sagas";
import { useInputs } from "../../hooks/useInput";
import "./../../styles/PrintTag.scss";
import { PRINT_TAGS_COLUMN, PRINT_TAGS_DATA_TYPE } from "../../util/columns";
import { useMsal } from "@azure/msal-react";
import TableFilter from "../common/TableFilter/TableFilter";
import Button from "../common/Button/Button";
import Label from "../common/Label/Label";
import { useValidation, ValidationRule } from "../../hooks/useValidation";
import {
  ACTION,
  GenericObject,
  getDuplicates,
  hasValue,
} from "../../util/utils";
import { ColumnsType } from "antd/es/table";
import ColorTag from "../common/ColorTag/ColorTag";
import { printLabelReport } from "../framePick/sagas";
import PDFStream from "../common/PDFStream/PDFStream";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import GenericTable from "../common/Table/GenericTable";

type PrintTagDetail = {
  TagColor: string;
  PrintedBy: string;
};
type ColorColumnType = {
  title: string;
  dataIndex: string;
  render: (PrintColor: PrintTagDetail[]) => number;
};

const EndColumn = [
  {
    title: "Status",
    dataIndex: "IsArchived",
    render: (IsArchived: string): ReactNode => {
      const status = IsArchived ? "Archive" : "Active";
      return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
    },
  },
];

const trim = (s: string) => {
  return s.trim();
};

const getColorColumns = (columns: PRINT_TAGS_DATA_TYPE[]) => {
  const colors: ColumnsType<PRINT_TAGS_DATA_TYPE> = [];
  const colorsObj: any = {};
  columns.forEach((column: any) => {
    column.PrintTagDetailsList.forEach((printTag: PrintTagDetail) => {
      if (colorsObj[printTag.TagColor] === undefined) {
        colors.push({
          title: printTag.TagColor,
          dataIndex: "PrintTagDetailsList",
          render: (PrintTagDetailsList: PrintTagDetail[]) => {
            let printed = false;
            const length = PrintTagDetailsList.filter(
              ({ TagColor, PrintedBy }) => {
                if (TagColor === printTag.TagColor) {
                  if (PrintedBy) {
                    printed = true;
                  }
                  return true;
                }
              }
            ).length;
            return (
              <label className={printed ? "tag-count" : ""}>{length}</label>
            );
          },
        });
        colorsObj[printTag.TagColor] = true;
      }
    });
  });
  return { colors: Object.keys(colorsObj), column: colors };
};
const Rules = {
  UPCCode: new ValidationRule().isRequired(),
  RemedyTicketNumber: new ValidationRule().isRequired(),
  OfficeNumber: new ValidationRule().isRequired("Office location is required"),
};

const defaultData = {};
function PrintTags() {
  const { accounts } = useMsal();
  const userName = accounts?.length ? accounts[0].name : "";
  const userEmail = accounts?.length ? accounts[0].username : "";
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(true);
  const [apiLoader, setApiLoader] = useState(false);
  const [inputs, onInputChange, updateValue, resetInputs] = useInputs({
    defaultData,
    userName,
  });
  const errors: GenericObject = useValidation(inputs, Rules);
  const [stream, setStream] = useState<any>("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PRINT_TAGS_DATA_TYPE[]>([]);

  const { loading, officeLocationList, data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const tagsData = useMemo(
    () =>
      data.sort((a: PRINT_TAGS_DATA_TYPE, b: PRINT_TAGS_DATA_TYPE) => {
        return (
          new Date(b.ReceivedDateTime).valueOf() -
          new Date(a.ReceivedDateTime).valueOf()
        );
      }),
    [data]
  );
  const colors: any = getColorColumns(tagsData);
  const tagColumns = useMemo(
    () => PRINT_TAGS_COLUMN.concat(colors.column).concat(EndColumn),
    [tagsData]
  );

  const [form] = Form.useForm();

  const { Option } = Select;

  const showModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };

  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    inputs["UPCCode"] =
      inputs["UPCCode"] &&
      inputs["UPCCode"].replaceAll("\n", ",").split(",").filter(trim).map(trim);
    try {
      const res = await createPrintTag(inputs);
      if (res) {
        handleClose();
        showSuccessNotification(res);
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data);
    }
    setConfirmLoading(false);
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: PRINT_TAGS_DATA_TYPE[]
    ) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: PRINT_TAGS_DATA_TYPE) => ({
      disabled: record.OfficeNum === "Disabled OfficeNum", // Column configuration not to be checked
      name: record.OfficeNum,
    }),
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const onPrint = async () => {
    const data = {
      Ids: selectedRow.map((row: PRINT_TAGS_DATA_TYPE) => row.Id),
      Color: inputs.color,
      PrintedBy: userName,
    };
    setApiLoader(true);
    await updateTag(data)
      .then(async (res: any) => {
        if (res?.MetaData) {
          setSelectedRow([]);
          updateValue("color", undefined);
          console.log("Call gerenate pdf report");
        }
      })
      .catch((error: any) => {
        showErrorNotification(
          "Print tag color status update failed. Please contact support"
        );
      });

    const reportPayload = {
      reportName: "PrintTags",
      parameters: {},
    };

    await new Promise((resolve) => setTimeout(resolve, 2000));
    await printLabelReport(reportPayload)
      .then((result: string) => {
        if (result) {
          setStream(result);
        }
      })
      .catch((error: any) => {
        showErrorNotification(
          "Print tag color status update failed. Please contact support"
        );
      });
    setApiLoader(false);
  };
  const onArchive = async () => {
    const data = {
      Ids: selectedRow.map((row: PRINT_TAGS_DATA_TYPE) => row.Id),
      IsArchieve: true,
      ArchivedBy: userName,
    };
    try {
      setApiLoader(true);
      const res = await archiveTag(data);
      if (res.MetaData) {
        setSelectedRow([]);
        showSuccessNotification("Print tag request archived successfully");
      } else {
        showErrorNotification(
          "Print tag request archive failed. Please try again"
        );
      }
    } catch (error: any) {
      showErrorNotification(
        "Print tag request archive failed. Please try again"
      );
    }
    setApiLoader(false);
  };

  useEffect(() => {
    if (!visible || !apiLoader) {
      dispatch(fetchPrintTags({ active: active }));
    }
  }, [active, visible, apiLoader]);

  useEffect(() => {
    dispatch(fetchOfficeLocation({}));
  }, []);

  useEffect(() => {
    if (!selectedRow.length) {
      updateValue("color", undefined);
    }
  }, [selectedRow.length]);

  const handleClose = () => {
    setVisible(false);
    setOpen(false);
    form.resetFields();
    resetInputs();
  };

  const spinning = loading;
  return (
    <Spin tip="Loading..." spinning={apiLoader}>
      <div>
        <Divider />
        <Breadcrumb>
          <span>Print Tag</span>
        </Breadcrumb>
        <Form
          layout={"horizontal"}
          form={form}
          initialValues={{ layout: "horizontal" }}
        >
          <TableFilter>
            <div className="form align-items-end">
              <Label label="Color">
                <Select
                  placeholder="Select"
                  value={inputs.color}
                  disabled={!selectedRow.length || spinning}
                  onChange={(value) => updateValue("color", value)}
                  size="small"
                  allowClear
                  style={{ width: 120 }}
                >
                  {colors.colors.length &&
                    colors.colors.map((color: string) => {
                      return (
                        <Option key={color} value={color}>
                          {color}
                        </Option>
                      );
                    })}
                </Select>
              </Label>
              <Button
                disabled={!selectedRow.length || !inputs["color"] || spinning}
                onClick={onPrint}
                size="middle"
              >
                Print Tag
              </Button>
              <Button
                disabled={!selectedRow.length || spinning}
                onClick={onArchive}
                size="middle"
              >
                Archive Request
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={showModal}
                size="middle"
                disabled={spinning}
              >
                Create New Request
              </Button>
            </div>
            <Label
              label="Display Active Only"
              className="flex-row align-items-center fs-6 gap-2"
            >
              <Switch
                size="small"
                defaultChecked
                onChange={(flag) => {
                  setActive(flag);
                }}
              />
            </Label>
          </TableFilter>
          <Form.Item>
            <GenericTable
              rowkey="Id"
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedRow.map(
                  (row: PRINT_TAGS_DATA_TYPE) => row.Id
                ),
                ...rowSelection,
              }}
              columns={tagColumns}
              dataSource={tagsData}
              loading={spinning}
              moduleKey={MODULE_KEY}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
              xScroll={null}
              yScroll={"calc(80vh - 17.5em)"}
              dateFormat={true}
            />
            <p className="text-end">
              * Indicates specific color tag requests are already printed.
            </p>
          </Form.Item>
        </Form>
        {stream ? (
          <Modal
            visible={true}
            onOk={() => setStream("")}
            onCancel={() => setStream("")}
            footer={null}
            width={1000}
            destroyOnClose
          >
            <PDFStream onPrint={() => setStream("")} stream={stream} />
          </Modal>
        ) : null}
        <Drawer
          title="Create New Tag Request"
          placement="right"
          visible={visible}
          onClose={handleClose}
          width={500}
          closable={false}
          className="dc-drawer-panel"
          forceRender={true}
          extra={
            <Space>
              <Button
                onClick={
                  !inputs.OfficeNumber &&
                  !inputs.UPCCode &&
                  !inputs.RemedyTicketNumber
                    ? handleClose
                    : closeModal
                }
              >
                Cancel
              </Button>
              <div className="text-end">
                <Button
                  disabled={
                    hasValue(errors) ||
                    !inputs.OfficeNumber ||
                    !inputs.UPCCode ||
                    !inputs.RemedyTicketNumber
                  }
                  onClick={handleOk}
                >
                  Submit Request
                </Button>
              </div>
            </Space>
          }
        >
          <Spin spinning={loading || confirmLoading}>
            <Form
              form={form}
              initialValues={{ remember: false }}
              autoComplete="off"
              className="add-picker-form"
              layout="vertical"
              style={{
                backgroundColor: "#f3f3f3",
                borderRadius: "16px",
                marginRight: "24px",
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Card>
                <Form.Item
                  validateStatus={errors["OfficeNumber"] && "error"}
                  help={errors["OfficeNumber"]}
                  label="Office Location"
                  name="OfficeNumber"
                  className="label-text region-field"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Office"
                    optionFilterProp="children"
                    onChange={(value) => updateValue("OfficeNumber", value)}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {officeLocationList.length
                      ? officeLocationList.map(
                          ({ LocationName, LocationNumber }: any) => (
                            <Option
                              key={LocationNumber}
                              value={LocationNumber}
                            >{`${LocationNumber} (${LocationName})`}</Option>
                          )
                        )
                      : null}
                  </Select>
                </Form.Item>

                <Form.Item
                  validateStatus={errors["RemedyTicketNumber"] && "error"}
                  help={errors["RemedyTicketNumber"]}
                  label="Remedy Ticket No "
                  name="RemedyTicketNumber"
                  className="label-text input-field"
                  rules={[{ required: true }]}
                >
                  <Input name="RemedyTicketNumber" onChange={onInputChange} />
                </Form.Item>

                <Form.Item
                  validateStatus={errors["UPCCode"] ? "error" : ""}
                  help={errors["UPCCode"]}
                  label="UPC Codes"
                  name="UPCCode"
                  className="label-text area-field"
                  rules={[{ required: true }]}
                >
                  <TextArea
                    name="UPCCode"
                    placeholder="UPC Codes"
                    onChange={onInputChange}
                    rows={4}
                  />
                </Form.Item>
              </Card>
            </Form>
          </Spin>
        </Drawer>

        <Modal
          title="Request Cancel Confirmation"
          okText="Ok"
          centered
          style={{ top: 20 }}
          visible={open}
          onOk={handleClose}
          onCancel={() => setOpen(false)}
        >
          <span>Are you sure, want cancel the request?</span>
        </Modal>
      </div>
    </Spin>
  );
}

export default PrintTags;
