import { DatePicker, Divider, Form, Modal, Spin } from 'antd';
import './../../styles/OpenPull.scss'
import { DAILY_REPORT_COLUMNS, DAILY_REPORT_EXCEL_COLUMNS, DATE_FORMAT } from '../../util/columns';
import GenericTable from '../common/Table/GenericTable';
import { DAILY_REPORT_MODULE_KEY, fetchDailyReport } from './actions';
import UpdateOrderStatus from './UpdateOrderStatus';
import TableFilter from '../common/TableFilter/TableFilter';
import { showErrorNotification, showSuccessNotification } from '../../util/notifications';
import { fetchDailyExcelReport } from './sagas';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import Button from '../common/Button/Button';
import Breadcrumb from '../common/Breadcrumb/Breadcrumb';
import DateRangerPicker from '../common/DatePicker/DateRangerPicker';
import { formatDate } from '../../util/utils';
import filterColumns from './filterColumns';
import VendorExclusion from './vendorExclusion/VendorExclusion';
const ExcelJS = require("exceljs");

function DailyOrders() {
  let memoizeStartDate = dayjs().format("YYYY-MM-DD")
  let memoizeEndDate = dayjs().format("YYYY-MM-DD")
  const [dateRange, setDateRange] = useState({ startDate: memoizeStartDate, endDate: memoizeEndDate })
  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [vendorExclusions, setVendorExclusions] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const filters = filterColumns();
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  const showStatusModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisibleStatus(true);
  };
  const vendorExclusionsModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVendorExclusions(true);
  };

  useEffect(() => {
    onLoad();
  }, []);
  const handleData = () => {
    setLoader(true);
    if (dateRange) {
      onLoad();
    }
    setLoader(false)
  }
  const onLoad = () => {
    let data: any = {
      offset: 0,
      limit: 10000,
      startDate: dayjs(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(dateRange.endDate).format("YYYY-MM-DD"),
    }
    dispatch(fetchDailyReport(data));
  }
  const handleReset = () => {
    setDateRange({ startDate: memoizeStartDate, endDate: memoizeEndDate });
    form.resetFields();
    setSelectedRow([]);
    let data: any = {
      offset: 0,
      limit: 10000,
      startDate: memoizeStartDate,
      endDate: memoizeEndDate,
    }
    dispatch(fetchDailyReport(data));
  }
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setSelectedRow([])
    },
    selectedRowKeys: selectedRow.map((row: any) => row.OrderNumber),
  };

  const exportExcelFile = async () => {
    setLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    //sheet.properties.defaultRowHeight = 25;

    sheet.columns = DAILY_REPORT_EXCEL_COLUMNS;
    let payload: any = {
      offset: 0,
      limit: 10000,
      startDate: dayjs(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(dateRange.endDate).format("YYYY-MM-DD"),
      format: "excel"
    }
    try {
      const res = await fetchDailyExcelReport(payload);
      if (res.length > 0) {
        setOpen(false);
        setLoader(false);
        let color;
        const promise = Promise.all(
          res.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              OrderDate: dayjs(item.OrderDate).format("YYYY-MM-DD"),
              FrameSource: item.FrameSource,
              LabName: item.LabName,
              Region: item.Region,
              District: item.District + ', ' + item.DistrictManager,
              OfficeName: item.OfficeName,
              OrderNumber: item.OrderNumber,
              VendorName: item.VendorName,
              ItemName: item.ItemName,
              UPCCode: item.UPCCode,
              LabStatus: item.LabStatus,
              LocationStatus: item.LocationStatus,
              Notes: item.Notes,
              ItemStatus: item.ItemStatus,
              Status: item.Status,
              Ref: item.ReferenceNumber
            });
            sheet.getRow(rowNumber).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: item.CellColor }
            };
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Daily-Order-Report.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        setOpen(false);
        setLoader(false);
        showErrorNotification("No data available for the selected date")
      }
    } catch (error: any) {
      setOpen(false);
      setLoader(false);
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };
  return (
    <div className='frame-pick-wrapper'><Spin tip="Loading..." spinning={loader}>
      <Divider />
      <Breadcrumb>
        <span>Update Order Status</span>
      </Breadcrumb>
      <Form
        layout={"horizontal"}
        form={form}
        name='order'
        onFinish={handleData}
      >
        <TableFilter>
          <div className='form align-items-end'>
            <DateRangerPicker dateRange={dateRange} setDateRange={(val: any) => setDateRange(val)} memoizeStartDate={memoizeStartDate} memoizeEndDate={memoizeEndDate} spinning={loader} />
            <div style={{ display: "flex" }}>
              <Button
                htmlType='submit'
                size="middle"
                style={{ marginLeft: 10 }}
              >
                Get Order Details
              </Button>
              <Button htmlType="button" style={{ marginLeft: 10, marginRight: 10 }} onClick={handleReset} size="middle">
                Reset
              </Button>
              <Button
                onClick={showStatusModal}
                size="middle"
                disabled={!selectedRow.length}
              >
                Update Order Status
              </Button>
              <Button
                htmlType="button" style={{ marginLeft: 10, marginRight: 10 }}
                onClick={closeModal}
                size="middle"
              >
                Export Daily Orders
              </Button>
              <Button
                onClick={vendorExclusionsModal}
                size="middle"
              >
                Vendor Exlusion
              </Button>
            </div>
          </div>
        </TableFilter>
      </Form>
      <GenericTable
        rowkey="OrderNumber"
        columns={filters.columns}
        rowSelection={rowSelection}
        pagination={{ defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['100', '500', '1000', '3000', '5000'] }}
        moduleKey={DAILY_REPORT_MODULE_KEY}
        loading={loader}
        xScroll={1000}
        yScroll={'calc(80vh - 17.5em)'}
        form={form}
      />
      <UpdateOrderStatus visible={visibleStatus} setVisibleStatus={setVisibleStatus} setSelectedRow={setSelectedRow} selectedRow={selectedRow} onLoad={onLoad} />
      <VendorExclusion visible={vendorExclusions} setLoading={setLoader} setVisible={setVendorExclusions} loading={loader} />
      <Modal
        title="Excel export Confirmation"
        okText="Ok"
        open={open}
        centered
        confirmLoading={loader}
        style={{ top: 20 }}
        onOk={() => exportExcelFile()}
        onCancel={() => setOpen(false)}
      >
        <span>Are you sure you want to export this data?</span>
      </Modal>
    </Spin>
    </div>
  )
}
export default DailyOrders;
