import {
  Card,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import dayjs from "dayjs";
import { Key, useEffect, useState } from "react";
import { DATE_FORMAT } from "src/util/columns";
import { STATUS } from "src/util/utils";
import { getCollectionByVendor } from "./sagas";

function KitDetailForm({
  form,
  kitDate,
  setKitDate,
  selectedVendor,
  setSelectedVendor,
  setCollection,
  collection,
  shippingDate,
  setShippingDate,
  memoizeDate,
  setVendor,
  vendor,
}: any) {
  const { Option } = Select;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedVendor) {
      getCollectionByVendor(selectedVendor).then((res: any) => {
        const sortedCollection: any = res?.sort((a: any, b: any) => {
          return a.Description.localeCompare(b.Description, "sv");
        });
        setCollection(sortedCollection);
      });
    }
  }, [selectedVendor]);
  const handleShippingDate = (value: any) => {
    if (dayjs(value).isBefore(dayjs(), "day")) {
      setOpen(true);
    } else {
      setShippingDate(
        value && value !== "Invalid date"
          ? dayjs(value).format(DATE_FORMAT)
          : null
      );
    }
  };
  return (
    <>
      <Card className="mb-4">
        <Form
          form={form}
          initialValues={{ remember: false }}
          autoComplete="off"
          className="add-form"
          layout="vertical"
        >
          {" "}
          <div>
            <Form.Item
              label="Kit Name"
              name="KitName"
              className=""
              rules={[
                {
                  required: true,
                  message: `Please enter kit name!`,
                },
                {
                  max: 50,
                  message: `Kit name should be less then 50 characters.`,
                },
              ]}
            >
              <Input
                placeholder="Enter Kit Name"
                name="kitName"
                maxLength={51}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Vendor"
              name="Vendor"
              className=""
              rules={[{ required: true, message: "Please select vendor" }]}
            >
              <Select
                showSearch={true}
                placeholder="Select Vendors"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(val) => {
                  setSelectedVendor(val);
                  form.resetFields(["Collection"]);
                }}
              >
                {vendor?.map((item: any, index: any) => {
                  return (
                    <Option key={index} value={item.ID} label={item.VendorName}>
                      {item.VendorName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Collection"
              name="Collection"
              className="label-text region-field"
              rules={[{ required: true, message: "Please select collection" }]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch={true}
                placeholder="Select collection"
                optionFilterProp="children"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={!selectedVendor}
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                {collection?.map((item: any, index: Key | null | undefined) => {
                  return (
                    <Option
                      key={index}
                      value={item?.Description}
                      label={item?.Description}
                    >
                      {item?.Description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Date"
              name="Date"
              className=""
              //rules={[{ required: true, message: "Please select date" }]}
            >
              <DatePicker
                format={DATE_FORMAT}
                name="Date"
                defaultValue={kitDate && dayjs(kitDate)}
                value={kitDate && dayjs(kitDate)}
                disabledDate={(d: any) => !d || d.isAfter(dayjs())}
                placeholder="Select Date"
                onChange={(value: any) =>
                  setKitDate(
                    value && value !== "Invalid date"
                      ? dayjs(value).format(DATE_FORMAT)
                      : memoizeDate
                  )
                }
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Planned Ship Date" name="shippingDate">
              <DatePicker
                format={DATE_FORMAT}
                name="shippingDate"
                defaultValue={shippingDate && dayjs(shippingDate)}
                value={shippingDate && dayjs(shippingDate)}
                placeholder="Select Date"
                onChange={(value: any) => handleShippingDate(value)}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Status"
              name="Status"
              className=""
              // rules={[{ required: true, message: "Please select status" }]}
            >
              <Select
                placeholder="Select Status"
                optionLabelProp="label"
                maxTagCount="responsive"
                defaultValue={true}
              >
                {STATUS?.map((item: any, index: any) => {
                  return (
                    <Option key={index} value={item?.value} label={item?.label}>
                      {item?.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Card>
      <Modal
        title="Shipping Date Confirmation"
        okText="Ok"
        centered
        style={{ top: 20 }}
        open={open}
        onOk={() => {
          setShippingDate(dayjs(form.getFieldValue("shippingDate")));
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
          setShippingDate(null);
          form.setFieldValue("shippingDate", null);
        }}
      >
        <span>Past Date has been entered. Please confirm it is correct.</span>
      </Modal>
    </>
  );
}
export default KitDetailForm;
