import { Card, Drawer, Form, Input, Space, Spin } from "antd";
import { useState } from "react";
import Button from "src/components/common/Button/Button";
import { showErrorNotification } from "src/util/notifications";
import { checkUPC } from "./sagas";

function AddFrame({
  visible,
  setVisibleStatus,
  frameData,
  setFrameData,
  newFrameData,
  setNewFrameData,
  accounts,
  vendor,
  collection,
}: any) {
  const [searchUPCCodes] = Form.useForm();
  const [frameDetail] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleClose = () => {
    setVisibleStatus(false);
    frameDetail.resetFields();
    searchUPCCodes.resetFields();
  };
  const handleOk = async () => {
    try {
      const row = (await searchUPCCodes.validateFields()) as any;
      setConfirmLoading(true);
      const fields = frameDetail.getFieldsValue();
      if (fields.UPC) {
        let submitData: any = {
          UPC: fields.UPC,
          Collection: fields.Collection,
          Model: fields.Model,
          Color: fields.Color,
          ColorDescription: fields.ColorDescription,
          EyeSize: fields.EyeSize,
          FrameType: fields.FrameType,
          MEDCost: fields.MEDCost,
          ExtCost: fields.ExtCost,
          type: "add",
          CreatedBy: accounts[0]?.username,
        };
        frameData?.push({ ...submitData });
        setFrameData([...frameData]);
        const found = newFrameData.some(
          (item: any) => item.UPC === fields.UPC && item.type === "add"
        );
        if (!found) {
          newFrameData.push({ UPC: fields.UPC, type: "add" });
          setNewFrameData([...newFrameData]);
        }
        searchUPCCodes.resetFields();
        frameDetail.resetFields();
        setConfirmLoading(false);
        setVisibleStatus(false);
      } else {
        setConfirmLoading(false);
        showErrorNotification("No frame found with that UPC");
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error.response.data;
        showErrorNotification(errMessage);
      }
      setConfirmLoading(false);
    }
  };
  const handleSearch = async () => {
    try {
      const row = (await searchUPCCodes.validateFields()) as any;
      const fields = searchUPCCodes.getFieldsValue();
      const found = frameData?.some(
        (el: any) => parseInt(el.UPC) === parseInt(fields.UPC)
      );
      if (found) {
        showErrorNotification("Entered UPC code is already exist in the list.");
      } else {
        setConfirmLoading(true);
        const res: any = checkUPC({
          UPC: fields.UPC,
          Vendor: vendor[0],
          Collection: collection,
        });
        res
          .then((response: any) => {
            if (response) {
              if (response?.UPC) {
                frameDetail.setFieldsValue({
                  Collection: response?.Collection,
                  Model: response?.Model,
                  Color: response?.Color,
                  ColorDescription: response?.ColorDescription,
                  EyeSize: response?.EyeSize,
                  UPC: response?.UPC,
                  FrameType: response?.FrameType,
                  MEDCost: response?.UnitCost,
                });
              } else {
                showErrorNotification("No frame found with that UPC");
              }
            }
            setConfirmLoading(false);
          })
          .catch((error: any) => {
            showErrorNotification(error?.response?.data?.error?.messages[0]);
            setConfirmLoading(false);
          });
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error.response.data.error.messages[0];
        showErrorNotification(errMessage);
      }
    }
  };

  return (
    <>
      <Drawer
        title="Add Frame"
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={400}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Submit</Button>
          </Space>
        }
      >
        <Spin spinning={confirmLoading}>
          <div className="">
            {" "}
            <Form
              form={searchUPCCodes}
              initialValues={{ remember: false }}
              autoComplete="off"
              className="add-picker-form mb-2"
              layout="vertical"
              name="searchUPCCodes"
              style={{
                backgroundColor: "#f3f3f3",
                borderRadius: "16px",
                marginRight: "24px",
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Card>
                <div>
                  <Form.Item
                    label="UPC Code"
                    name="UPC"
                    className=""
                    rules={[{ required: true, message: "Please enter UPC" }]}
                  >
                    <Input name="UPC" />
                  </Form.Item>
                </div>
                <div className="mt-2 d-flex justify-content-end">
                  {" "}
                  <Button onClick={handleSearch}>Search</Button>
                </div>
              </Card>
            </Form>
            <Form
              form={frameDetail}
              initialValues={{ remember: false }}
              autoComplete="off"
              className="add-picker-form"
              layout="vertical"
              name="frameDetail"
              style={{
                backgroundColor: "#f3f3f3",
                borderRadius: "16px",
                marginRight: "24px",
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Card>
                <div>
                  <Form.Item
                    label="Collection"
                    name="Collection"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="Collection" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Model"
                    name="Model"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="Model" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Color"
                    name="Color"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="Color" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Color Description"
                    name="ColorDescription"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="ColorDescription" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Eye Size"
                    name="EyeSize"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="EyeSize" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="UPC"
                    name="UPC"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="UPC" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Frame Type"
                    name="FrameType"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="frameType" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="MED Cost"
                    name="MEDCost"
                    className="label-text region-field"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled={true} name="MEDCost" />
                  </Form.Item>
                </div>
              </Card>
            </Form>
          </div>
        </Spin>
      </Drawer>
    </>
  );
}

export default AddFrame;
