import { Drawer, Space, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { showSuccessNotification } from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { PP_COLUMNS } from "src/util/columns";
const Draggable = require("array-move");
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from "react-sortable-hoc";
import React from "react";

// Define types for props to improve TypeScript support
interface ColumnDrawerProps {
  visible: boolean;
  setVisibleStatus: any;
  filterValues: {
    drawerColumns: any[];
    columns: any[];
    data?: any[];
  };
  setTableColumns: any;
  cookies: Record<string, any>;
  setCookie: any;
  removeCookie: any;
}

const SortableItem = SortableElement(
  React.forwardRef(
    ({ className, style, ...restProps }: any, ref: React.Ref<any>) => (
      <tr ref={ref} className={className} style={style} {...restProps} />
    )
  )
);

// Refactor SortableBody to use forwardRef

const SortableBody = SortableContainer(
  React.forwardRef(
    ({ className, style, ...restProps }: any, ref: React.Ref<any>) => (
      <tbody ref={ref} className={className} style={style} {...restProps} />
    )
  )
);

const ColumnDrawer: React.FC<ColumnDrawerProps> = ({
  visible,
  setVisibleStatus,
  filterValues,
  setTableColumns,
  cookies,
  setCookie,
  removeCookie,
}) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);

  const handleColumnsOrder = useCallback(
    (cookiesData: any[], defaultColumns: any[]) => {
      const filteredRecords = defaultColumns.filter(
        (col) =>
          !cookiesData.some(
            (cookieCol) => cookieCol.dataIndex === col.dataIndex
          )
      );
      return [...cookiesData, ...filteredRecords].filter(
        (item) => item.title !== "action"
      );
    },
    []
  );

  const defaultColumns = useMemo(() => {
    return cookies["pp-columns"]?.length
      ? handleColumnsOrder(cookies["pp-columns"], filterValues?.drawerColumns)
      : filterValues?.drawerColumns;
  }, [cookies, filterValues]);

  // Initialize data and columns
  useEffect(() => {
    if (filterValues?.data?.length) {
      setDataSource(defaultColumns);
      setTableColumns(
        cookies["pp-columns"]?.length ? defaultColumns : filterValues?.columns
      );
      setSelectedRow(
        cookies["pp-columns"]?.length
          ? cookies["pp-columns"]
          : filterValues?.columns
      );
    }
  }, [filterValues?.data?.length]);

  const actionColumn = useMemo(() => {
    return filterValues?.columns?.find((item) => item.title === "action");
  }, [filterValues]);

  const handleClose = () => {
    setVisibleStatus(false);
    setSelectedRow(filterValues?.columns);
  };

  const handleOk = () => {
    const orderedColumns = dataSource.filter((item) =>
      selectedRow.some((row) => row.dataIndex === item.dataIndex)
    );
    const actionAppend = [...orderedColumns, actionColumn].filter(Boolean);

    removeCookie("pp-columns", []);
    setCookie("pp-columns", actionAppend);
    setTableColumns(actionAppend);
    showSuccessNotification("Columns updated successfully");
    setVisibleStatus(false);
  };

  const handleRestoreColumns = () => {
    removeCookie("pp-columns", []);
    setCookie("pp-columns", filterValues?.columns);
    setSelectedRow(filterValues?.columns);
    setTableColumns(filterValues?.columns);
    setVisibleStatus(false);
  };

  const drawerTitle = "Manage Columns";

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRow(selectedRows);
    },
    clearSelection: () => {
      setSelectedRow([]);
    },
    selectedRowKeys: selectedRow.map((row) => row?.dataIndex),
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = Draggable.arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el: any) => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    const index = dataSource.findIndex(
      (x) => x.dataIndex === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      open={visible}
      onClose={handleClose}
      width={450}
      closable={false}
      forceRender
      className="dc-drawer-panel"
      extra={
        <Space>
          <Button onClick={handleRestoreColumns}>Restore Default</Button>
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Save</Button>
        </Space>
      }
    >
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={PP_COLUMNS}
        rowSelection={rowSelection}
        rowKey="dataIndex"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </Drawer>
  );
};

export default ColumnDrawer;
