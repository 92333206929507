import { Breadcrumb as AntBreadcrumb } from "antd";
import { useNavigate } from "react-router-dom";
import "./breadcrumb.scss";

interface BreadcrumbProps {
  children: React.ReactNode | React.ReactNode[];
}

function Breadcrumb({ children }: BreadcrumbProps) {
  const navigate = useNavigate();

  // Prepare breadcrumb items
  const breadcrumbItems = [
    {
      title: (
        <span
          onClick={() => navigate("/dashboard")}
          style={{ cursor: "pointer" }}
        >
          Home
        </span>
      ),
    },
    ...(Array.isArray(children)
      ? children.map((child, index) => ({
          title: <span>{child}</span>, // Add more styling/logic if needed
        }))
      : [{ title: <span>{children}</span> }]), // Handle the case where children is a single item
  ];

  return (
    <AntBreadcrumb className="breadcrumb-wrapper" items={breadcrumbItems} />
  );
}

export default Breadcrumb;
