import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, Input, InputRef, Space, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useTableProps from "../../../hooks/table/useTableProps";
import styles from "./GenericTable.module.scss";
import EditableCell, { EditableRow } from "./Columns/EditableCell";
import dayjs from "dayjs";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "src/util/columns";

interface GenericTableProps {
  columns: any[];
  dataSource?: any;
  onRowSelect?: any;
  selected?: any;
  rowkey: string;
  expandable?: any;
  moduleKey?: string;
  rowSelection?: any;
  loading?: boolean;
  xScroll?: any;
  yScroll?: any;
  hidePagination?: boolean;
  onSaveChanges?: any;
  pagination?: any;
  hideTotal?: boolean;
  style?: any;
  form?: any;
  isReset?: boolean;
  rowClassName?: any;
  dateFormat?: boolean;
  filteredData?: any;
  setFilteredData?: any;
  removeLogo?: boolean;
}

const GenericTable: React.FC<GenericTableProps> = ({
  columns,
  onRowSelect = () => {},
  selected = {},
  rowkey,
  expandable,
  moduleKey,
  rowSelection,
  xScroll,
  yScroll,
  dataSource,
  hidePagination = false,
  pagination = false,
  hideTotal = false,
  onSaveChanges,
  style,
  form,
  isReset,
  rowClassName = "",
  dateFormat,
  filteredData,
  setFilteredData,
  removeLogo = false,
}: GenericTableProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const searchInput = useRef<InputRef>(null);
  const [total, setTotal] = useState<number>(0);

  // Handle Search
  const handleSearch = useCallback(
    (selectedKeys: string[], confirm: any, dataIndex: string) => {
      confirm();
      setSearchText(selectedKeys[0] || "");
      setSearchedColumn(dataIndex);
    },
    []
  );

  // Handle Reset
  const handleReset = useCallback(() => {
    setIndex((prevIndex: number) => prevIndex + 1);
    if (filteredData && setFilteredData) {
      setFilteredData([]);
    }
  }, [filteredData, setFilteredData]);

  useEffect(() => {
    handleReset();
  }, [isReset, handleReset]);

  // Date Format Check
  const checkDateFormat = (text: any, dataIndex: any) => {
    // Array of date formats to attempt to parse the `text`
    const formats = [
      "YYYY-MM-DDTHH:mm:ss",
      "YYYY-MM-DDTHH:mm:ss.SSS",
      "YYYY-MM-DDTHH:mm:ssZ",
      "MM/DD/YYYY  :)  HH*mm*ss",
    ];

    // Try to parse `text` with the given formats and check if it's a valid date
    const res = dayjs(text, formats, false).isValid();
    //console.log(res, text);
    // If `text` is valid and not a number, format and return the date
    if (res && typeof text !== "number") {
      return dateFormat
        ? dayjs(text).format(DATE_TIME_FORMAT) // If `dateFormat` is true, format with `DATE_TIME_FORMAT`
        : !/^\d+$/.test(text) // If `text` is not a number, format with `DATE_FORMAT`
        ? dayjs(text).format(DATE_FORMAT)
        : text?.toString() || "N/A"; // If `text` is a number, return it as a string or "N/A"
    } else {
      // If `text` is invalid or a number/boolean, return it as a string or "N/A"
      return typeof text === "number" || typeof text === "boolean"
        ? text.toString() // Return number/boolean as a string
        : text?.toString() || "N/A"; // Otherwise, return `text` as a string or "N/A"
    }
  };

  // Get Column Search Props
  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e: { target: { value: any } }) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterDropdownProps: {
      onOpenChange: (visible: any) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={checkDateFormat(text, dataIndex)}
        />
      ) : (
        checkDateFormat(text, dataIndex)
      ),
  });

  // Filter Columns with Search Props
  const filterColumn: any = columns.map((column: any) => {
    if (column.filterMultiple) {
      return { ...column, ...getColumnSearchProps(column.dataIndex) };
    }
    return column.filtered
      ? {
          ...column,
          onCell: (record: any, index: any) => ({
            inputType: column.dataIndex,
            dataIndex: column.dataIndex,
            title: column.title,
            editable: column.filtered,
            onSaveChanges,
            record,
            index,
          }),
        }
      : { ...column };
  });

  // Table Props
  const tableProps = useTableProps(
    moduleKey,
    [rowSelection],
    rowkey,
    filterColumn,
    dataSource
  );

  // Set Total on Data Source Change
  useEffect(() => {
    setTotal(tableProps.dataSource.length);
  }, [tableProps.dataSource]);

  const handleChange = useCallback(
    (
      pagination: any,
      filters: any,
      sorter: any,
      extra: { action: string; currentDataSource: any }
    ) => {
      setTotal(extra.currentDataSource?.length);
      if (
        (extra.action === "filter" || extra.action === "sort") &&
        filteredData
      ) {
        setFilteredData(
          extra.currentDataSource?.length > 0
            ? extra.currentDataSource
            : ["none"]
        );
      }
    },
    [filteredData, setFilteredData]
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div className={styles["table-container"]}>
      <div className={styles["table-wrapper"]}>
        <Table
          key={index}
          className={styles["table-common"]}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => onRowSelect(record, rowIndex),
          })}
          rowClassName={(record: any) => {
            if (
              selected &&
              rowkey &&
              selected[rowkey] === record[rowkey] &&
              rowkey !== "OrderNumber"
            ) {
              return "selected-background";
            }
            if (rowClassName && record?.PreviousReceived !== record?.Quantity) {
              return "editable-row colored-data-row";
            }
            return "editable-row";
          }}
          components={components}
          {...tableProps}
          rowSelection={rowSelection}
          expandable={expandable}
          scroll={{ x: xScroll, y: yScroll }}
          style={style}
          pagination={
            pagination
              ? {
                  // showTotal: (total) => setTotal(total),
                  defaultPageSize: pagination.defaultPageSize,
                  showSizeChanger: true,
                  pageSizeOptions: pagination.pageSizeOptions,
                }
              : false
          }
          onChange={handleChange}
        />
      </div>

      <div className={styles["pagination-container"]}>
        {!hidePagination && (
          <div className={styles["pagination-actions-wrapper"]}>
            {!hideTotal && (
              <div className={styles["total-container"]}>
                <div className={styles["total-title"]}>TOTAL RECORDS</div>
                <div className={styles["total-count"]}>
                  <span>{total}</span>
                </div>
              </div>
            )}
            {!removeLogo && (
              <img
                alt="logo"
                className={styles["logo"]}
                src={"./images/logo.svg"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenericTable;
