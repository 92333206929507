import { useSelector } from "react-redux";
import { ACTION, insertAt } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { PrinterOutlined } from "@ant-design/icons";
import { FEDEX_OFFICE_COLUMN } from "src/util/columns";
import { MODULE_KEY } from "./actions";
import dayjs from "dayjs";

export default function filterColumns(reprint: ((record: any) => void) | any) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    align: "center",
    width: 80,
    render: (_: any, record: any) => {
      return (
        <>
          {dayjs(record?.CreatedDate).isSame(dayjs(), "day") ? (
            <Button
              icon={<PrinterOutlined />}
              onClick={() => reprint(record)}
              children={undefined}
            ></Button>
          ) : (
            "No Action"
          )}
        </>
      );
    },
  };
  let columns = insertAt(FEDEX_OFFICE_COLUMN, 10, 0, actionColumn);

  return { columns };
}
