import React, { useEffect, useState } from "react";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionType,
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import styles from "./styles/App.module.scss";
import { reactApp } from "./util/constants/common.constants";
import { flow, set } from "lodash";
import Header from "./components/header/Header";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Button, Layout, Typography } from "antd";
import SIGN_IN_LOGO from "./assets/images/sign-in-logo.svg";
import {
  kitsManagementEnabled,
  replenismentEnabled,
  viewOrderEnabled,
} from "./components/navbar/sagas";
import routes from "./routes";
/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  //useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const routerBasename = reactApp().routerBasename;

  const filteredRoutes = () => {
    const modulelinks = routes?.sort(
      (a: { title: string }, b: { title: any }) => {
        return a.title.localeCompare(b.title);
      }
    );
    return flow()(routes);
  };

  const [url, setUrl] = useState<any>(filteredRoutes());
  useEffect(() => {
    featureFlagRes();
  }, []);

  const featureFlagRes = async () => {
    try {
      // Use Promise.all to handle multiple async requests concurrently
      const results = await Promise.all([
        replenismentEnabled({}),
        viewOrderEnabled({}),
        kitsManagementEnabled({}),
      ]);

      // Process the responses from each API call
      const [replenismentRes, viewOrderRes, kitsManagementRes] = results;

      // Handle "Replenishment" feature flag
      if (!replenismentRes.Enabled) {
        setUrl((prevUrl: any[]) =>
          prevUrl.filter((element: any) => element.title !== "DC Replenishment")
        );
      }

      // Handle "View Orders" feature flag
      if (!viewOrderRes.Enabled) {
        setUrl((prevUrl: any[]) =>
          prevUrl.filter((element: any) => element.title !== "View Orders")
        );
      }

      // Handle "New Style Kits" feature flag
      if (!kitsManagementRes.Enabled) {
        setUrl((prevUrl: any[]) =>
          prevUrl.filter((element: any) => element.title !== "New Style Kits")
        );
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Feature flag error details:", error.response.data);
      }
    }
  };

  return (
    <Router basename={routerBasename}>
      <div className={styles.App}>
        <div className={styles.container}>
          <Header routes={url} />

          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={(result) => (
              <section className="login-screen">
                <section className="login-card">
                  <img
                    src={SIGN_IN_LOGO}
                    width={120}
                    height={120}
                    alt="Sign-in logo"
                  />

                  <div>{result.error?.message}</div>
                </section>
              </section>
            )}
            loadingComponent={() => (
              <section className="login-screen">
                <section className="login-card">
                  <img
                    src={SIGN_IN_LOGO}
                    width={120}
                    height={120}
                    alt="Sign-in logo"
                  />

                  <div>Validating Login</div>
                </section>
              </section>
            )}
          >
            <main className={styles.main}>
              <Routes>
                {/* Route Redirection */}

                <Route path="/" element={<Navigate to="/dashboard" />} />

                {/* Dynamically rendered routes */}

                {filteredRoutes().map((route: any, index: number) => {
                  // If route has children, map over them to create sub-routes

                  if (route.children) {
                    return route.children.map((item: any, i: number) => {
                      const RouteComponent = item.content;
                      return (
                        <Route
                          key={i}
                          path={item.path}
                          element={RouteComponent && <RouteComponent />}
                        />
                      );
                    });
                  } else {
                    // For routes without children
                    const RouteComponent = route.content;
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        element={RouteComponent && <RouteComponent />}
                      />
                    );
                  }
                })}
              </Routes>
            </main>
          </MsalAuthenticationTemplate>
        </div>
      </div>
    </Router>
  );
};

export default function App() {
  return <MainContent />;
}
