import { useMsal } from "@azure/msal-react";
import { Card, Form, Input, Drawer, Space, Spin, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../util/notifications";
import Button from "../../common/Button/Button";
import GenericTable from "../../common/Table/GenericTable";
import {
  VENDOR_AVIALBLE_COLUMN,
  VENDOR_EXCLUSION_COLUMN,
} from "../../../util/columns";
import { MODULE_KEY } from "../../vendor/vendor-actions";
import { VENDOR_EXLUSION_MODULE_KEY, fetchVendorExclusion } from "../actions";
import { LeftCircleTwoTone, RightCircleTwoTone } from "@ant-design/icons";
import { fetchVendorList } from "../../vendor/vendor-sagas";
import { getExcludedVendors, saveExcludedVendors } from "../sagas";
import { ACTION } from "../../../util/utils";

interface VendorExclusionType {
  visible: boolean;
  loading: boolean;
  setLoading: any;
  setVisible: any;
}

function VendorExclusion({
  visible,
  loading = false,
  setLoading,
  setVisible,
}: VendorExclusionType) {
  const [vednorExlusionList, setVednorExlusionList] = useState<any[]>([]);
  const [vendorList, setVendorList] = useState<any[]>([]);
  const [vendorSelectedRow, setVendorSelectedRow] = useState<any>([]);
  const [excludedVendorSelectedRow, setExcludedVendorSelectedRow] =
    useState<any>([]);
  const [isReset, setIsReset] = useState(false);

  const handleClose = () => {
    setVendorSelectedRow([]);
    setExcludedVendorSelectedRow([]);
    setVisible(false);
    handleInitialData();
    setIsReset(true);
  };
  const removeDuplicates = (firstArr: any, secArr: any) => {
    const filterValue = firstArr.filter((list: any) => {
      return !secArr.find((mList: any) => list.ID === mList.ID);
    });
    return filterValue;
  };
  const handleInitialData = () => {
    Promise.all([fetchVendorList(), getExcludedVendors({})]).then(
      (res: any) => {
        const filterVendors = removeDuplicates(res[0], res[1]);
        setVendorList(filterVendors);
        setVednorExlusionList(res[1]);
      }
    );
  };
  useEffect(() => {
    handleInitialData();
  }, []);
  const vendorRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setVendorSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setVendorSelectedRow([]);
    },
    selectedRowKeys: vendorSelectedRow.map((row: any) => row.ID),
    hideSelectAll: true,
  };
  const excludedVendorRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setExcludedVendorSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setExcludedVendorSelectedRow([]);
    },
    selectedRowKeys: excludedVendorSelectedRow.map((row: any) => row.ID),
    hideSelectAll: true,
  };
  const handleVendors = (type: any) => {
    setLoading(true);
    let payload;
    if (type === "availableToExcluded") {
      setVednorExlusionList([...vednorExlusionList, ...vendorSelectedRow]);
      const filterVendors = removeDuplicates(vendorList, vendorSelectedRow);
      setVendorList(filterVendors);
      payload = vendorSelectedRow.map((list: any) => {
        return {
          Action: "add",
          VendorID: list.ID,
          VendorName: list.VendorName,
        };
      });
      setVendorSelectedRow([]);
    } else {
      setVendorList([...vendorList, ...excludedVendorSelectedRow]);
      const filterVendors = removeDuplicates(
        vednorExlusionList,
        excludedVendorSelectedRow
      );
      setVednorExlusionList(filterVendors);
      payload = excludedVendorSelectedRow.map((list: any) => {
        return {
          Action: "delete",
          VendorID: list.ID,
          VendorName: list.VendorName,
        };
      });
      setExcludedVendorSelectedRow([]);
    }
    try {
      if (payload.length) {
        saveExcludedVendors(payload)
          .then((res: any) => {
            showSuccessNotification(res);
            handleInitialData();
            setVendorSelectedRow([]);
            setExcludedVendorSelectedRow([]);
            setLoading(false);
          })
          .catch((e: any) => {
            showErrorNotification(e.response.data.message);
            setLoading(false);
          });
      } else {
        showErrorNotification("Please select atleast one vendor");
      }
    } catch (error: any) {
      showErrorNotification(error.response.data);
    }
    setLoading(false);
  };
  return (
    <>
      <Drawer
        title="Vendor Exclusion"
        placement="right"
        open={visible}
        onClose={handleClose}
        width={900}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button danger onClick={handleClose}>Close</Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <section className="vendor-wrapper">
            <GenericTable
              rowkey="ID"
              rowSelection={vendorRowSelection}
              columns={VENDOR_AVIALBLE_COLUMN}
              dataSource={vendorList}
              moduleKey={MODULE_KEY}
              xScroll={100}
              hidePagination={true}
              style={{ height: "80vh", width: "40vh" }}
              isReset={isReset}
            />
            <section className="d-flex flex-column justify-content-center gap-3">
              <RightCircleTwoTone
                disabled={
                  !vendorSelectedRow.length || !excludedVendorSelectedRow.length
                }
                onClick={() => handleVendors("availableToExcluded")}
                style={{ fontSize: "2rem" }}
              />
              <LeftCircleTwoTone
                onClick={() => handleVendors("excludedToAvailable")}
                disabled={
                  !vendorSelectedRow.length || !excludedVendorSelectedRow.length
                }
                style={{ fontSize: "2rem" }}
              />
            </section>
            <GenericTable
              rowkey="ID"
              columns={VENDOR_EXCLUSION_COLUMN}
              dataSource={vednorExlusionList}
              rowSelection={excludedVendorRowSelection}
              hideTotal={true}
              moduleKey={VENDOR_EXLUSION_MODULE_KEY}
              xScroll={100}
              hidePagination={true}
              style={{ height: "80vh", width: "40vh" }}
              isReset={isReset}
            />
          </section>
        </Spin>
      </Drawer>
    </>
  );
}

export default VendorExclusion;
