import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Select,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Spin,
  Modal,
  UploadFile,
  Tooltip,
} from "antd";
import { Key, SetStateAction, useEffect, useState } from "react";
import { saveProductPlanogramMapping } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { getFrameCollection } from "../OfficeCollection/sagas";
import { LifeCycleStatusList, SKUGrade } from "src/util/utils";
import { DATE_FORMAT } from "src/util/columns";
import dayjs from "dayjs";
import { fetchVendorList } from "src/components/vendor/vendor-sagas";
import { getCollectionByVendor } from "src/components/kits/kitMaintenance/sagas";
import { UploadOutlined } from "@ant-design/icons";
import { UploadImages } from "src/components/common/UploadImages/UploadImages";

interface AddPPMappingType {
  visible: boolean;
  setVisibleStatus: any;
  onLoad: any;
  data: any;
  setData: any;
}

function AddPPMapping({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
}: AddPPMappingType) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [FrameArrangerID, setFrameArrangerId] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  let memoizeDate = dayjs().format(DATE_FORMAT);
  const [statusDate, setStatusDate] = useState<any>(memoizeDate);
  const [recallDate, setRecallDate] = useState<any>(memoizeDate);
  const [effectiveDate, setEffectiveDate] = useState<any>(memoizeDate);
  const [noReplenishmentDate, setNoReplenishmentDate] = useState<any>("");
  const [receiptDate, setReceiptDate] = useState<any>(memoizeDate);
  const [vendors, setVendors] = useState<any>([]);
  const [vendor, setVendor] = useState(null);
  const [vendorName, setVendorName] = useState(null);
  const [collections, setCollections] = useState<any>([]);
  const [collection, setCollection] = useState(null);
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [deletedImages, setDeletedImages] = useState<any>([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchVendorList().then((res: any[]) => {
      const sortedVendor = res?.sort((a: any, b: any) => {
        return a.VendorName.localeCompare(b.VendorName, "en", {
          numeric: true,
        });
      });
      setVendors(sortedVendor);
    });
  }, []);

  useEffect(() => {
    if (vendor) {
      getCollectionByVendor(vendor).then((res: any) => {
        const sortedCollection: any = res?.sort((a: any, b: any) => {
          return a.Description.localeCompare(b.Description, "sv");
        });
        setCollections(sortedCollection);
      });
      setVendorName(vendors.find((e: any) => e.ID == vendor)?.VendorName);
    }
  }, [vendor]);
  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        ID1: data?.ID1,
        AssortmentIds: data?.AssortmentIds,
        Collection: data?.Collection,
        Vendor: data?.Vendor,
        Model: data?.Model,
        StyleName: data?.StyleName,
        ColorNumber: data?.ColorNumber,
        ColorDescription: data?.ColorDescription,
        EyeSize: data?.EyeSize,
        Bridge: data?.Bridge,
        Temple: data?.Temple,
        A: data?.A,
        B: data?.B,
        ED: data?.ED,
        Material: data?.Material,
        Edge: data?.Edge,
        UPC: data?.UPC,
        SKUQty: data?.SKUQty,
        FrameType: data?.FrameType,
        Gender: data?.Gender,
        FrameName: data?.FrameName,
        MEDCost: data?.MEDCost,
        Wholesale: data?.Wholesale,
        Retail: data?.Retail,
        RecallDate:
          data?.RecallDate !== null &&
          data?.RecallDate !== "" &&
          data?.RecallDate !== "Invalid date"
            ? dayjs(data.RecallDate)
            : "",
        ItemGroup: data?.ItemGroup,
        NoReplenishmentDate:
          data.NoReplenishmentDate !== null &&
          data.NoReplenishmentDate !== "Invalid date"
            ? dayjs(data.NoReplenishmentDate)
            : "",
        EffectiveDate:
          data.EffectiveDate !== null && data.EffectiveDate !== "Invalid date"
            ? dayjs(data.EffectiveDate)
            : "",
        PhasingInStyle: data?.PhasingInStyle,
        Min: data?.Min,
        Max: data?.Max,
        StockedAtDC: data?.StockedAtDC,
        LiveCost: data?.LiveCost,
        LiveWholeSale: data?.LiveWholeSale,
        FrameColorFamily: data?.FrameColorFamily,
        FrameShape: data?.FrameShape,
        AgeGroup: data?.AgeGroup,
        Rank: data?.Rank,
        ReceiptDate:
          data.ReceiptDate !== null &&
          data?.RecallDate !== "" &&
          data.ReceiptDate !== "Invalid date"
            ? dayjs(data?.ReceiptDate)
            : "",
        ItemType: data?.ItemType,
        LiveRetail: data?.LiveRetail,
        LifeCycleStatus: data?.LifeCycleStatus,
        LifeCycleStatusDate:
          data.LifeCycleStatusDate !== null &&
          data.LifeCycleStatusDate !== "Invalid date"
            ? dayjs(data.LifeCycleStatusDate)
            : "",
        SKUGrade: data?.SKUGrade,
      };
      frameDetailForm.setFieldsValue(editFieldObj);
      const imageList = data?.AssociatedPlanogramImages?.map(
        (item: any, i: number) => {
          return { ...item, name: item.Name, url: item.BlobPath };
        }
      );
      setFileList(imageList);
      setStatusDate(
        data.LifeCycleStatusDate !== null &&
          data.LifeCycleStatusDate !== "Invalid date"
          ? dayjs(data.LifeCycleStatusDate)
          : ""
      );
      setRecallDate(
        data.RecallDate !== null && data.RecallDate !== "Invalid date"
          ? dayjs(data.RecallDate)
          : ""
      );
      setNoReplenishmentDate(
        data.NoReplenishmentDate !== null &&
          data.NoReplenishmentDate !== "Invalid date"
          ? dayjs(data.NoReplenishmentDate)
          : ""
      );
      setEffectiveDate(
        data.EffectiveDate !== null && data.EffectiveDate !== "Invalid date"
          ? dayjs(data.EffectiveDate)
          : ""
      );
      setReceiptDate(
        data.ReceiptDate !== null && data.ReceiptDate !== "Invalid date"
          ? dayjs(data.ReceiptDate)
          : ""
      );
    }
  }, [data]);

  useEffect(() => {
    Promise.all([getFrameCollection({})]).then((res) => {
      const sortedFAId: any = res[0]?.sort((a: any, b: any) => {
        return a.Vendor !== null && a.Vendor.localeCompare(b.Vendor, "sv");
      });
      setFrameArrangerId(sortedFAId);
    });
  }, []);

  const handleClose = () => {
    // setConfirmLoading(true);
    setData([]);
    setVisibleStatus(false);
    frameDetailForm.resetFields();
    setFileList([]);
    setDeletedImages([]);
    setModal(false);
    // setConfirmLoading(false);
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;
      let payload: any = {
        ID1: fields.ID1,
        AssortmentIds: fields.AssortmentIds || null,
        Collection: fields.Collection || null,
        Vendor:
          Object.keys(data).length > 0 ? fields.Vendor : vendorName || null,
        Model: fields.Model,
        StyleName: fields.StyleName,
        ColorNumber: fields.ColorNumber,
        ColorDescription: fields.ColorDescription,
        EyeSize: fields.EyeSize,
        Bridge: fields.Bridge,
        Temple: fields.Temple,
        A: fields.A,
        B: fields.B,
        ED: fields.ED,
        Material: fields.Material,
        Edge: fields.Edge,
        UPC: fields.UPC,
        SKUQty: Number(fields.SKUQty),
        FrameType: fields.FrameType,
        Gender: fields.Gender,
        FrameName: fields.FrameName,
        MEDCost: fields.MEDCost,
        Wholesale: fields.Wholesale,
        Retail: fields.Retail,
        RecallDate: fields.RecallDate,
        ItemGroup: fields.ItemGroup,
        NoReplenishmentDate: fields.NoReplenishmentDate,
        EffectiveDate: fields.EffectiveDate,
        PhasingInStyle: Number(fields.PhasingInStyle ? 1 : 0),
        Min: Number(fields.Min),
        Max: Number(fields.Max),
        StockedAtDC: Number(fields.StockedAtDC ? 1 : 0),
        LiveCost: Number(fields.LiveCost),
        LiveWholeSale: Number(fields.LiveWholeSale),
        FrameColorFamily: fields.FrameColorFamily,
        FrameShape: fields.FrameShape,
        AgeGroup: fields.AgeGroup,
        Rank: Number(fields.Rank),
        ReceiptDate: fields.ReceiptDate,
        ItemType: Number(fields.ItemType),
        LiveRetail: fields.LiveRetail,
        LifeCycleStatus: fields.LifeCycleStatus,
        LifeCycleStatusDate: fields.LifeCycleStatusDate,
        SKUGrade: fields.SKUGrade,
        imageFileList: fileList?.filter((item: any) => !item.Id),
      };
      payload = {
        ...payload,
        ID1: data?.ID1,
        deletedImages: deletedImages,
      };

      res = await saveProductPlanogramMapping(payload);

      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error.response.data.error.messages[0];
        showErrorNotification(errMessage);
      }
    }
  };

  const drawerTitle = Object.keys(data).length
    ? "Edit Product Planogram"
    : "Add Product Planogram";
  const checkNoReplenishmentDate = () => {
    if (Object.keys(data)?.length > 0 && noReplenishmentDate !== "") {
      setModal(true);
    } else {
      handleOk();
    }
  };
  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        open={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button danger={true} onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={checkNoReplenishmentDate}>Save</Button>
          </Space>
        }
      >
        {/* <Spin tip="Loading..." spinning={confirmLoading}> */}
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              {Object.keys(data).length > 0 && (
                <div>
                  <Form.Item
                    label="Assortments"
                    name="AssortmentIds"
                    className="label-text region-field"
                  >
                    <Select
                      mode="multiple"
                      showSearch={true}
                      placeholder="Select Assortments"
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      disabled={!!Object.keys(data).length}
                      optionLabelProp="label"
                      maxTagCount="responsive"
                    >
                      {FrameArrangerID?.map((item: any, index) => {
                        return (
                          <Option
                            key={index}
                            value={item?.AssortmentId}
                            label={`${item?.AssortmentName}- (${item?.AssortmentId})`}
                          >
                            {item?.AssortmentName}-({item?.AssortmentId})
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              <div>
                <Form.Item
                  label="Vendor"
                  name="Vendor"
                  className=""
                  rules={[{ required: true, message: "Please select vendor" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Vendors"
                    optionFilterProp="children"
                    disabled={!!Object.keys(data).length}
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(val: SetStateAction<null>) => {
                      setVendor(val);
                      frameDetailForm.resetFields(["Collection"]);
                    }}
                  >
                    {vendors?.map((item: any, index: any) => {
                      return (
                        <Option
                          key={index}
                          value={item.ID}
                          label={item.VendorName}
                        >
                          {item.VendorName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Collection"
                  name="Collection"
                  className="label-text region-field"
                  rules={[
                    { required: true, message: "Please select collection" },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch={true}
                    placeholder="Select collection"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length || !vendor}
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    optionLabelProp="label"
                    onChange={(val: SetStateAction<null>) => {
                      setCollection(val);
                    }}
                  >
                    {collections?.map(
                      (item: any, index: Key | null | undefined) => {
                        return (
                          <Option
                            key={index}
                            value={item?.Description}
                            label={item?.Description}
                          >
                            {item?.Description}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Card>
            <Card>
              <Card>
                <Button icon={<UploadOutlined />} onClick={() => setOpen(true)}>
                  Upload Image
                </Button>
                <div style={{ marginTop: 16 }}>
                  {fileList?.map(
                    (
                      file: {
                        Name: string | undefined;
                        BlobPath: string | undefined;
                        url: string | undefined;
                        name: string | undefined;
                        originFileObj: Blob | MediaSource;
                      },
                      index: any
                    ) => (
                      <Tooltip
                        key={index}
                        placement="top"
                        title={
                          <img
                            key={`${file?.name}-${index}`} // Ensure the key is unique
                            src={
                              file.originFileObj
                                ? URL.createObjectURL(file.originFileObj!)
                                : file?.url
                            }
                            alt={file?.name}
                            style={{
                              width: 80,
                              height: 80,
                            }}
                          />
                        }
                      >
                        <img
                          key={`${file?.name}-${index}`} // Ensure the key is unique
                          src={
                            file.originFileObj
                              ? URL.createObjectURL(file.originFileObj!)
                              : file?.url
                          }
                          alt={file?.name}
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 10,
                            marginTop: 10,
                          }}
                        />
                      </Tooltip>
                    )
                  )}
                </div>
              </Card>
              <div>
                <Form.Item
                  label="Model"
                  name="Model"
                  className="label-text region-field"
                >
                  <Input name="Model" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Style Name"
                  name="StyleName"
                  className="label-text region-field"
                >
                  <Input name="StyleName" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Color Number"
                  name="ColorNumber"
                  className="label-text region-field"
                >
                  <Input name="ColorNumber" maxLength={10} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Color Description"
                  name="ColorDescription"
                  className="label-text region-field"
                >
                  <Input name="ColorDescription" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Eye Size"
                  name="EyeSize"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^-?\d+(\.\d{1,2})?$/),
                      message: `Eye size should be decimal`,
                    },
                  ]}
                >
                  <Input name="EyeSize" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Bridge"
                  name="Bridge"
                  className="label-text region-field"
                >
                  <Input name="Bridge" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Temple"
                  name="Temple"
                  className="label-text region-field"
                >
                  <Input name="Temple" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="A"
                  name="A"
                  className="label-text region-field"
                >
                  <Input name="A" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="B"
                  name="B"
                  className="label-text region-field"
                >
                  <Input name="B" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="ED"
                  name="ED"
                  className="label-text region-field"
                >
                  <Input name="ED" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Material"
                  name="Material"
                  className="label-text region-field"
                >
                  <Input name="Material" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Edge"
                  name="Edge"
                  className="label-text region-field"
                >
                  <Input name="Edge" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="UPC"
                  name="UPC"
                  className="label-text region-field"
                  rules={[{ required: true, message: "Please enter UPC" }]}
                >
                  <Input name="UPC" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="SKU Qty"
                  name="SKUQty"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `SKU Qty should be numeric`,
                    },
                  ]}
                >
                  <Input name="SKUQty" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Type"
                  name="FrameType"
                  className="label-text region-field"
                >
                  <Input name="FrameType" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Gender"
                  name="Gender"
                  className="label-text region-field"
                >
                  <Input name="Gender" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Name"
                  name="FrameName"
                  className="label-text region-field"
                >
                  <Input name="FrameName" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="MED Cost"
                  name="MEDCost"
                  className="label-text region-field"
                >
                  <Input name="MEDCost" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Wholesale"
                  name="Wholesale"
                  className="label-text region-field"
                >
                  <Input name="Wholesale" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Retail"
                  name="Retail"
                  className="label-text region-field"
                >
                  <Input name="Retail" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Recall Date"
                  name="RecallDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="LifeCyRecallDatecleStatusDate"
                    value={recallDate && dayjs(recallDate)}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setRecallDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Item Group"
                  name="ItemGroup"
                  className="label-text region-field"
                >
                  <Input name="ItemGroup" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="NoReplenishDate"
                  name="NoReplenishmentDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="NoReplenishmentDate"
                    value={noReplenishmentDate && dayjs(noReplenishmentDate)}
                    placeholder="Select Date"
                    disabledDate={(d: any) => !d || d.isAfter(dayjs())}
                    onChange={(value: any) =>
                      setNoReplenishmentDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Effective Date"
                  name="EffectiveDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="EffectiveDate"
                    value={effectiveDate && dayjs(effectiveDate)}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setEffectiveDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="PhasingInStyle"
                  className="label-text region-field"
                >
                  <Checkbox name="PhasingInStyle" value={true}>
                    {" "}
                    Phasing{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Min"
                  name="Min"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Min should be numeric`,
                    },
                  ]}
                >
                  <Input name="Min" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Max"
                  name="Max"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Max should be numeric`,
                    },
                  ]}
                >
                  <Input name="Max" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="StockedAtDC"
                  className="label-text region-field"
                >
                  <Checkbox name="StockedAtDC" value={true}>
                    {" "}
                    Stocked at DC{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="LiveCost"
                  name="LiveCost"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Live cost should be numeric`,
                    },
                  ]}
                >
                  <Input name="LiveCost" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="LiveWholeSale"
                  name="LiveWholeSale"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Live whole sale should be numeric`,
                    },
                  ]}
                >
                  <Input name="LiveWholeSale" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="LiveRetail"
                  name="LiveRetail"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Live retail should be numeric`,
                    },
                  ]}
                >
                  <Input name="LiveRetail" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Color Family"
                  name="FrameColorFamily"
                  className="label-text region-field"
                >
                  <Input name="FrameColorFamily" maxLength={6} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Shape"
                  name="FrameShape"
                  className="label-text region-field"
                >
                  <Input name="FrameShape" maxLength={9} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Age Group"
                  name="AgeGroup"
                  className="label-text region-field"
                >
                  <Input name="AgeGroup" maxLength={8} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Rank"
                  name="Rank"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Rank should be numeric`,
                    },
                  ]}
                >
                  <Input name="Rank" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Receipt Date"
                  name="ReceiptDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="ReceiptDate"
                    value={receiptDate && dayjs(receiptDate)}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setReceiptDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Item Type"
                  name="ItemType"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Item type should be numeric`,
                    },
                  ]}
                >
                  <Input name="ItemType" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Life Cycle Status"
                  name="LifeCycleStatus"
                  className="label-text region-field"
                  rules={[{ message: "Please select Life Cycle Status" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Life Cycle Status"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {LifeCycleStatusList?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item} label={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Life Cycle Status Date"
                  name="LifeCycleStatusDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="LifeCycleStatusDate"
                    value={statusDate && dayjs(statusDate)}
                    disabledDate={(d: any) => !d || d.isAfter(dayjs())}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setStatusDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="SKU Grade"
                  name="SKUGrade"
                  className="label-text region-field"
                  rules={[{ message: "Please select SKU Grade" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select SKU Grade"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input: string, option: any) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {SKUGrade?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item} label={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
        {/* </Spin> */}
      </Drawer>
      <UploadImages
        onCancel={() => setOpen(false)}
        visible={open}
        fileList={fileList || []}
        setFileList={setFileList}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        deletedImages={deletedImages}
        setDeletedImages={setDeletedImages}
      />
      <Modal
        title="No Replenishment Date Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={modal}
        onOk={handleOk}
        onCancel={() => setModal(false)}
      >
        <span>
          Since you selected a no replenishment date, saving this will remove
          this SKU from all assortments. Are you sure you want to proceed?
        </span>
      </Modal>
    </>
  );
}

export default AddPPMapping;
