import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import { OFFICE_COLLECTION_LISTING_COLUMN } from "src/util/columns";
import Button from "src/components/common/Button/Button";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((record: any) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null[] | undefined = [];
  const collection: any | null | undefined = [];
  const collectionType: any | null | undefined = [];

  data?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    collection.push({ text: row.Collection, value: row.Collection });
    collectionType.push({
      text: row.CollectionType,
      value: row.CollectionType,
    });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let collectionTypeName = collectionType.filter(
    (ele: any, ind: any) =>
      ind ===
      collectionType.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "Vendor",
    sorter: (a: { Vendor: string }, b: { Vendor: any }) =>
      a.Vendor?.localeCompare(b.Vendor),
    sortDirections: ["ascend", "descend"],
    filters: vendorName?.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    }),
    filterSearch: true,
    onFilter: (value: any, record: { Vendor: string | any[] }) =>
      record.Vendor !== null && record.Vendor.indexOf(value) === 0,
    width: 120,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };
  const collectionColumns = {
    title: "Collection Name",
    dataIndex: "Collection",
    sorter: (a: { Collection: string }, b: { Collection: any }) =>
      a.Collection?.localeCompare(b.Collection),
    sortDirections: ["ascend", "descend"],
    filterSearch: true,
    filters: collectionName?.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    }),
    onFilter: (value: any, record: { Collection: string | any[] }) =>
      record.Collection !== null && record.Collection.indexOf(value) === 0,
    width: 120,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };
  const collectionTypeColumns = {
    title: "Collection Type",
    dataIndex: "CollectionType",
    sorter: (a: { CollectionType: string }, b: { CollectionType: any }) =>
      a.CollectionType?.localeCompare(b.CollectionType),
    sortDirections: ["ascend", "descend"],
    filters: collectionTypeName,
    onFilter: (value: any, record: { CollectionType: string | any[] }) =>
      record.CollectionType !== null &&
      record.CollectionType.indexOf(value) === 0,
    width: 120,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };

  let columns = insertAt(OFFICE_COLLECTION_LISTING_COLUMN, 5, 0, vendorColumns);
  columns = insertAt(columns, 6, 0, collectionColumns);
  columns = insertAt(columns, 7, 0, collectionTypeColumns);
  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    width: 90,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            icon={<EditOutlined />}
            children={undefined}
            onClick={() => editDrawer(record)}
          ></Button>
          <Button
            style={{ marginLeft: "10px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record?.ID)}
            children={undefined}
            danger={true}
          ></Button>
        </div>
      );
    },
  };
  columns = insertAt(columns, 13, 0, actionColumn);

  return { columns, data };
}
