import { LeftCircleTwoTone, RightCircleTwoTone } from "@ant-design/icons";
import { Card, Modal, Spin } from "antd";
import { SetStateAction, useEffect, useState } from "react";
import Button from "src/components/common/Button/Button";
import GenericTable from "src/components/common/Table/GenericTable";
import {
  AVIALBLE_OFFICE_COLUMN,
  SELECTED_OFFICES_COLUMN,
} from "src/util/columns";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import {
  AVAILABLE_OFFICE_MODULE_KEY,
  SELECTED_OFFICE_MODULE_KEY,
} from "./actions";
import { getOffices } from "src/components/systemPreference/sagas";
import { getPrePopulatedOffices, validateOfficeList } from "./sagas";
import styled from "styled-components";
import "./../../../styles/vendor.scss";
import TextArea from "antd/lib/input/TextArea";

function OfficeSelection({
  selectedOffices,
  setSelectedOffices,
  vendorName,
  kitId,
}: any) {
  const [initialAvailableOffice, setInitialAvailableOffice] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [availableOffices, setAvailableOffices] = useState<any[]>([]);
  const [availableOfficesSelectedRow, setavailableOfficesSelectedRow] =
    useState<any>([]);
  const [selectedOfficesSelectedRow, setSelectedOfficesSelectedRow] =
    useState<any>([]);
  const [isReset, setIsReset] = useState(false);
  const [open, setOpen] = useState(false);
  const [officeModal, setOfficeModal] = useState(false);
  const [officeEntryModal, setOfficeEntryModal] = useState(false);
  const [officeList, setOfficeList] = useState<any>([]);

  useEffect(() => {
    const location: SetStateAction<any[]> = [];
    getOffices({}).then((res: any) => {
      const filterOffices = removeDuplicates(res, selectedOffices);
      filterOffices?.map((item: any) => {
        location.push({
          LocationNumber: item.LocationNumber,
          LocationName: item.LocationName,
        });
      });
      setInitialAvailableOffice(location);
      setAvailableOffices(location);
    });
  }, []);

  const officeRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setavailableOfficesSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setavailableOfficesSelectedRow([]);
    },
    selectedRowKeys: availableOfficesSelectedRow.map(
      (row: any) => row.LocationNumber
    ),
  };
  const selectedOfficeRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedOfficesSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setSelectedOfficesSelectedRow([]);
    },
    selectedRowKeys: selectedOfficesSelectedRow.map(
      (row: any) => row.LocationNumber
    ),
  };
  const removeDuplicates = (firstArr: any, secArr: any) => {
    const filterValue = firstArr.filter((list: any) => {
      return !secArr.find(
        (mList: any) => list.LocationNumber === mList.LocationNumber
      );
    });
    return filterValue;
  };
  const handlePrePopulatedOffices = () => {
    setSelectedOffices([]);
    setAvailableOffices(initialAvailableOffice);
    setOpen(false);
    getPrePopulatedOffices(kitId).then((res) => {
      if (res?.length > 0) {
        setSelectedOffices([...res, ...availableOfficesSelectedRow]);
        const filterVendors = removeDuplicates(availableOffices, res);
        setAvailableOffices(filterVendors);
      } else {
        showErrorNotification("No office assigned to this vendor");
      }
    });
  };
  const handleVendors = (type: any) => {
    setConfirmLoading(true);
    let payload;
    if (type === "availableToSelected") {
      const sortedOffice = [
        ...selectedOffices,
        ...availableOfficesSelectedRow,
      ].sort((a: any, b: any) => {
        return a.LocationNumber.localeCompare(b.LocationNumber, "en", {
          numeric: true,
        });
      });
      setSelectedOffices(sortedOffice);
      const filterOffices = removeDuplicates(
        availableOffices,
        availableOfficesSelectedRow
      );
      setAvailableOffices(filterOffices);
      setavailableOfficesSelectedRow([]);
      if (availableOfficesSelectedRow?.length > 0) {
        showSuccessNotification("Office added to selected Office");
      } else {
        showErrorNotification("Please select aleast one office to move");
      }
    } else {
      const sortedOffice = [
        ...availableOffices,
        ...selectedOfficesSelectedRow,
      ].sort((a: any, b: any) => {
        return a.LocationNumber.localeCompare(b.LocationNumber, "en", {
          numeric: true,
        });
      });
      setAvailableOffices(sortedOffice);
      const filterOffices = removeDuplicates(
        selectedOffices,
        selectedOfficesSelectedRow
      );
      setSelectedOffices(filterOffices);
      setSelectedOfficesSelectedRow([]);
      if (selectedOfficesSelectedRow?.length > 0) {
        showSuccessNotification("Office removed from selected Office");
      } else if (!selectedOffices?.length) {
        showErrorNotification("No records are avialable to move");
      } else {
        showErrorNotification("Please select aleast one office to move");
      }
    }
    setConfirmLoading(false);
  };
  const handlePrePopulatedButton = () => {
    if (selectedOffices?.length > 0) {
      setOpen(true);
    } else {
      handlePrePopulatedOffices();
    }
  };
  const handleOfficesEntryButton = () => {
    if (selectedOffices?.length > 0) {
      setOfficeEntryModal(true);
    } else {
      setOfficeModal(true);
      setOfficeList([]);
    }
  };
  const handleEntryModalOk = () => {
    setOfficeEntryModal(false);
    setSelectedOffices([]);
    setAvailableOffices(initialAvailableOffice);
    setOfficeModal(true);
    setOfficeList([]);
  };
  const handleOfficeList = () => {
    if (officeList?.length > 0) {
      validateOfficeList(officeList?.trim().split("\n"))
        .then((res: any) => {
          if (res?.length > 0) {
            setOfficeModal(false);
            setSelectedOffices([...res, ...availableOfficesSelectedRow]);
            const filterVendors = removeDuplicates(availableOffices, res);
            setAvailableOffices(filterVendors);
            showSuccessNotification("Offices added successfully");
            setOfficeList([]);
          }
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data?.error?.messages[0]);
        });
    } else {
      showErrorNotification("Please enter atleast one office");
    }
  };

  return (
    <>
      <Card>
        <Spin spinning={confirmLoading}>
          <div className="quick-location-button">
            {" "}
            {kitId && (
              <>
                <Button
                  className="fixed-button"
                  onClick={handlePrePopulatedButton}
                >
                  Quick Office Lookup
                </Button>
              </>
            )}
            <Button className="fixed-button" onClick={handleOfficesEntryButton}>
              Quick Office Entry
            </Button>
          </div>

          <section className="vendor-wrapper">
            <GenericTable
              rowkey="LocationNumber"
              rowSelection={officeRowSelection}
              columns={AVIALBLE_OFFICE_COLUMN}
              dataSource={availableOffices}
              moduleKey={AVAILABLE_OFFICE_MODULE_KEY}
              loading={confirmLoading}
              xScroll={450}
              hidePagination={true}
              style={{ height: "70vh", width: "72vh" }}
              isReset={isReset}
            />
            <section className="d-flex flex-column justify-content-center gap-3">
              <RightCircleTwoTone
                disabled={
                  !availableOfficesSelectedRow?.length ||
                  !selectedOfficesSelectedRow.length
                }
                onClick={() => handleVendors("availableToSelected")}
                style={{ fontSize: "4rem" }}
              />
              <LeftCircleTwoTone
                onClick={() => handleVendors("selectedToAvailable")}
                disabled={
                  !availableOfficesSelectedRow?.length ||
                  !selectedOfficesSelectedRow?.length
                }
                style={{ fontSize: "4rem" }}
              />
            </section>
            <GenericTable
              rowkey="LocationNumber"
              columns={SELECTED_OFFICES_COLUMN}
              dataSource={selectedOffices}
              rowSelection={selectedOfficeRowSelection}
              hideTotal={true}
              moduleKey={SELECTED_OFFICE_MODULE_KEY}
              loading={confirmLoading}
              xScroll={100}
              hidePagination={true}
              style={{ height: "70vh", width: "72vh" }}
              isReset={isReset}
            />
          </section>
          <Modal
            title="Offices Lookup Confirmation"
            okText="Ok"
            centered
            confirmLoading={confirmLoading}
            style={{ top: 20 }}
            open={open}
            onOk={handlePrePopulatedOffices}
            onCancel={() => {
              setOpen(false);
            }}
          >
            <span>
              Are you sure you want to pre-populate these offices? if yes, all
              selected offices will be removed.
            </span>
          </Modal>
          <Modal
            title="Offices Entry Confirmation"
            okText="Ok"
            centered
            confirmLoading={confirmLoading}
            style={{ top: 20 }}
            open={officeEntryModal}
            onOk={handleEntryModalOk}
            onCancel={() => setOfficeEntryModal(false)}
          >
            <span>
              Are you sure you want to add these offices? if yes, all selected
              offices will be removed.
            </span>
          </Modal>
          <Modal
            title="Quick Office Entry"
            okText="Ok"
            centered
            width={500}
            confirmLoading={confirmLoading}
            style={{ top: 20 }}
            open={officeModal}
            onOk={handleOfficeList}
            onCancel={() => {
              setOfficeModal(false);
              setOfficeList([]);
            }}
          >
            <span>
              <TextArea
                onChange={(e) => setOfficeList(e.target.value)}
                value={officeList}
                rows={9}
              ></TextArea>
            </span>
          </Modal>
        </Spin>
      </Card>
    </>
  );
}

export default OfficeSelection;

const StyledButton = styled.button`
  margin-bottom: 10px;
  justify-content: center;
`;
