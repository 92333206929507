import React, { useMemo, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import { saveAs } from "file-saver";
import Button from "../Button/Button";
import dayjs from "dayjs";
import toUint8Array from 'base64-to-uint8array';

function PDFStream({ stream, fileName = "FrameLabels", onPrint = () => { } }) {
  const arr = useMemo(() => toUint8Array(stream), [stream]);

  const printHandler = () => {
    const blob = new Blob([arr]);
    const date = dayjs().format("DD-MM-YYYY");
    saveAs(blob, fileName + "-" + date + ".pdf");
    onPrint();
  };

  useEffect(() => {
    // Ensure the worker is correctly loaded from the installed version
    if (typeof window.pdfjsLib !== "undefined") {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";
    }
  }, []);

  return (
    <div>
      <Button onClick={printHandler} icon={<DownloadOutlined />}>
        Download PDF
      </Button>
      <div id="pdf-print">
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
          <Viewer fileUrl={arr} />
        </Worker>
      </div>
      <Button onClick={printHandler} icon={<DownloadOutlined />}>
        Download PDF
      </Button>
    </div>
  );
}

export default PDFStream;
