import { Descriptions, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { getKitData } from "./sagas";
import {
  PROCESS_KIT_FRAME_COLUMN,
  PROCESS_KIT_OFFICES_COLUMN,
} from "src/util/columns";
import styled from "styled-components";

function Preview({
  visible,
  setVisibleStatus,
  kitId,
  selectedOffices,
  poDate,
}: any) {
  const [kitData, setKitData] = useState({
    KitName: "",
    Vendor: "",
    Frames: [],
  });
  
  let [productShippingData, setProductShippingData] = useState<any>([]);
  let [totalQuntity, setTotalQuntity] = useState(0);
  const handleClose = () => {
    setVisibleStatus(false);
  };

  useEffect(() => {
    if (visible) {
      getKitData(kitId).then((res: any) => {
        setKitData(res);
      });
    }
  }, [visible]);

  useEffect(() => {
    handleOffice(selectedOffices);
    setTotalQuntity(selectedOffices?.length * kitData?.Frames?.length);
  }, [kitData?.Frames?.length, selectedOffices]);
  
  const handleOffice = (officeList: any) => {
    const location: { LocationNumber: any; Quantity: number }[] = [];
    officeList?.map((ele: any, index: any) => {
      const found = location?.some(
        (e: any) => e.LocationNumber === ele.LocationNumber
      );
      if (found) {
        location[index] = {
          ...ele,
          Quantity: kitData?.Frames?.length,
        };
        setProductShippingData(location);
      } else {
        location?.push({
          LocationNumber: ele?.LocationNumber,
          Quantity: kitData?.Frames?.length,
        });
        setProductShippingData(location);
      }
    });
  };
  
  return (
    <>
      <Modal
        title="Kit Processing Preview"
        open={visible}
        onCancel={handleClose}
        width={1000}
        centered
        className="dc-drawer-panel"
        forceRender={true}
        footer={false}
      >
        {visible && kitData && (
          <>
            <Descriptions column={3}>
              <Descriptions.Item label="PO Date">{poDate}</Descriptions.Item>
              <Descriptions.Item label="Kit Name">
                {kitData?.KitName}
              </Descriptions.Item>
              <Descriptions.Item label="Vendor Name">
                {kitData?.Vendor}
              </Descriptions.Item>
            </Descriptions>
            <Table
              columns={PROCESS_KIT_FRAME_COLUMN}
              dataSource={kitData?.Frames}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "50", "100"],
              }}
              scroll={{ y: 200 }}
              style={{ marginBottom: "25px" }}
            />
            <StyledHeader>
              <b>Product Shipping / Transfer Created</b>
            </StyledHeader>
            <Table
              style={{ marginTop: "25px" }}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "50", "100"],
              }}
              columns={PROCESS_KIT_OFFICES_COLUMN}
              dataSource={productShippingData}
              scroll={{ y: 200 }}
            />
            <StyledHeader>
              <b>Vendor Order & Receipt Section</b>
            </StyledHeader>
            <b>Total Quantity :</b> {totalQuntity}
            <br />
            <b>Total Locations:</b> {productShippingData?.length}
          </>
        )}
      </Modal>
    </>
  );
}

export default Preview;

const StyledHeader = styled.h6`
  color: #1b61a7;
`;
