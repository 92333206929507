import { useState } from "react";
import { Card, Image, Modal, Tooltip } from "antd";
import styled from "styled-components";
import imgUrl from "../../../assets/images/photo.png";
interface Props {
  param: any;
  modalTitle: any;
}

function Images({ param, modalTitle }: Props) {
  const [open, setOpen] = useState(false);

  const fileList = param?.split(",");
  const handleOffice = (locationName: any) => {
    setOpen(true);
  };

  return (
    <>
      {fileList?.length > 0 && (
        <Tooltip
          placement="top"
          title={
            <img
              key={`${fileList?.[0]?.name}`} // Ensure the key is unique
              src={fileList?.[0]}
              alt={fileList?.[0]?.name}
              style={{
                width: 80,
                height: 80,
              }}
            />
          }
        >
          <img
            key={`${fileList?.[0]?.name}`} // Ensure the key is unique
            src={imgUrl}
            alt={fileList?.[0]?.name}
            style={{
              width: 25,
              height: 25,
            }}
            onClick={() => setOpen(true)}
          />
        </Tooltip>
      )}

      <Modal
        title={modalTitle}
        okText="Ok"
        centered
        styles={{
          body: {
            overflowY: "scroll",
            maxHeight: "calc(100vh - 200px)",
            backgroundColor: "#F0F0F0",
          },
        }}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        width={600}
      >
        <StyledDiv>
          {fileList?.map((file: any, index: any) => {
            return (
              <Image
                key={`${file?.name}-${index}`} // Ensure the key is unique
                src={file}
                alt={file?.name}
                style={{
                  width: 200,
                  height: 200,
                  marginRight: "5px",
                }}
              />
            );
          })}
        </StyledDiv>
      </Modal>
    </>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export default Images;
