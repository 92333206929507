import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./data/config/authConfig";
import { Provider } from "react-redux";
import store from "./store/store";
import axios from "axios";
import AuthService from "./data/services/auth-service";
import "./antd.less";
import "./index.scss";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.css";
import { Buffer } from "buffer";
global.Buffer = Buffer;
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
//const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
  async (config: any) => {
    const token = await AuthService.refreshAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    } else {
      return;
    }
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject("Network Error");
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const token = await AuthService.refreshAccessToken();

      if (token) {
        originalRequest.headers["Authorization"] = "Bearer " + token;
      }

      return axios(originalRequest);
    } else {
      return Promise.reject(error);
    }
  }
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
